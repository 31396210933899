import React, { useState } from 'react';
import './Profile.scss';
import { Box, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

const SocialButton = ({ officeNumbers, checkObjectField, sendVia = "SMS", handleSendMessage, buttonLabel }) => {
    const [anchorElTel, setAnchorElTel] = useState(null);
    const id = 'social-popover';

    const handlePopOverOpen = (event) => {
        setAnchorElTel(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorElTel(null);
    };

    return (
        <>
            {officeNumbers?.length > 1 ? (
                <>
                    <button
                        className='dropdown-button'
                        onClick={handlePopOverOpen}
                        size='large'
                        style={{ textAlign: 'start' }}
                    >
                        {buttonLabel}
                    </button>
                    <Popover
                        id={id}
                        open={Boolean(anchorElTel)}
                        anchorEl={anchorElTel}
                        onClose={handlePopOverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {officeNumbers.map((field, index) => (
                                <Button
                                    key={index}
                                    variant='text'
                                    onClick={() => {
                                        handleSendMessage(checkObjectField(field), sendVia);
                                        handlePopOverClose();
                                    }}
                                    style={{ fontSize: 16, paddingLeft: 30, paddingRight: 30, width: '100%' }}
                                >
                                    {checkObjectField(field)}
                                </Button>
                            ))}
                        </div>
                    </Popover>
                </>
            ) : officeNumbers[0] ? (
                <div className='btn-option'>
                    <button
                        className='dropdown-button'
                        onClick={() => {
                            handleSendMessage(checkObjectField(officeNumbers[0]), sendVia);
                            handlePopOverClose();
                        }}
                    >
                        {buttonLabel}
                    </button>
                </div>
            ) : null}
        </>
    );
};

export default SocialButton;
