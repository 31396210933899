import BasePageComponent from "../components/BasePageComponent";
import "./TermsConditions.scss";

export default class TermsConditions extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return super.render(
      <div className="TermsConditions">
        <div className="container">
          <h1>CHuGO Pte Ltd</h1>
          <h3>LexisCard Terms &amp; Conditions </h3>
          <p>
            These terms and conditions govern your purchase of CHuGO Pte Ltd (“
            <span className="bold">Company</span>”,“<span className="bold">we</span>”, “
            <span className="bold">us</span>”, or “<span className="bold">our</span>")
            Services(s) across its various brands (CHuGO, ChopeShift, ChopeBoss,
            LeXisCard.Asia, LexisJobs.Asia (hereby known as our “Service(s)”)).
            By using this website, the corporate dashboard & the LexisCard 
            Mobile Application, you agree to be bound by its CONDITIONS OF
            USE as set out in this Terms &amp; Conditions policy and Privacy
            Policy, and all disclaimers and terms and conditions that appear
            elsewhere on our website. CHuGO Pte Ltd reserves the right to amend
            any terms and conditions without prior notice.{" "}
          </p>
          <h3>Definition</h3>
          <p>
            In these terms and conditions, "we" and "us" refers to CHuGO Pte Ltd
            and "you" refers to the person, firm or corporation purchasing the
            Service(s) indicated in the Invoice. Words spelt with initial
            capitalised letters are defined terms and shall bear the meanings
            set out in these terms and conditions – unless the context requires
            otherwise.{" "}
          </p>
          <h3>Payment Terms</h3>
          <p>
            All purchases made across the CHuGO Pte Ltd website, affiliated
            websites and Services(s) are subject to our acceptance. Acceptance
            and completion of the purchase between you and CHuGO Pte Ltd takes
            place on the dispatch of the purchased Service(s) to you and until
            that time we reserve the right to reject your order. CHuGO Pte Ltd
            reserves the right to reject the fulfilment of any order, for any
            reason whatsoever, even after an order is confirmed by, and paid for
            by the customer, with or without notice and we shall not be liable
            to any party for such termination or cancellation. In such event(s),
            we shall refund the price of the Service(s) purchased to the
            customer.{" "}
          </p>
          <p>
            Prices advertised on CHuGO Pte Ltd website, affiliated websites and
            Services(s)are in Singapore Dollars (SGD) and unless otherwise
            noted, include any prevailing Goods &amp; Services Tax (GST). Terms
            of payment are within our sole discretion and must be received by
            CHuGO Pte Ltd before our acceptance of an order. Prices and
            availability of Service(s) are subject to change without prior
            notice. Any errors will be corrected when discovered, and CHuGO Pte
            Ltd reserves the right to revoke any stated offer and to correct any
            errors, inaccuracies or omissions even after a purchase has been
            made.{" "}
          </p>
          <h3>Product/ Service Offering</h3>
          <p>
            CHuGO Pte Ltd reserves the right to revise its promotions/offerings
            and may revise and/or discontinue services at any time without prior
            notice to customers. Upon mutual agreement with you, we will replace
            Service(s) that have equal or better functionality and performance
            of the Service(s) purchased, but changes between what is purchased
            and what is described are possible.{" "}
          </p>
          <h3>User Submission and Use of User-Generated Content:</h3>
          <p>
            Should you send submissions (for example – reviews/contest-related
            entries) at our request, you agree that we may, at any time, without
            restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium such submissions. We are and shall be
            under no obligation:{" "}
          </p>
          <ol>
            <li>To maintain and submissions in confidence</li>
            <li>To pay conpensation for any submissions</li>
          </ol>
          <p>
            You agree that the contents of your submissions will not violate any
            right of any third-party, including copyright, trademark, privacy,
            personality or other personal or proprietary right. You further
            agree that your comments will not contain libellous or otherwise
            unlawful, abusive or obscene material. You agree to not use a false
            e-mail address, pretend to be someone other than yourself, or
            otherwise mislead us or third-parties. You are solely responsible
            for the accuracy and truthfulness of any submission you make. We
            take no responsibility and assume no liability for any such
            submissions.{" "}
          </p>
          <h3>Intellectual Property</h3>
          <p>
            All copyright, patent, intellectual and other property rights in
            information contained herein is owned by the CHuGO Pte Ltd. No
            rights of any kind are licensed or assigned or shall otherwise pass
            to persons accessing such information. Under no circumstances should
            information contained herein or any part of it be copied, reproduced
            or redistributed. Any and all personal data collected by the Company
            from time to time may be used and disclosed for such purposes and to
            such persons in accordance with CHuGO Pte Ltd’s Privacy Policy
            statement.{" "}
          </p>
          <h3>Systems</h3>
          <p>
            These Services and the Internet in general may be subject to system
            delays and failures beyond our reasonable control. As such, you may
            have difficulty accessing this website. The Company shall not be
            held liable for any damages, losses or expenses of any kind, whether
            direct, indirect, special, consequential or incidental, arising out
            of or in connection with your access to, use of or browsing on the
            website. In this regard, all warranties or representations of any
            kind, whether expressed or implied by law, equity or statutes, are
            excluded to the extent permissible under the applicable law.{" "}
          </p>
          <h3>International Use</h3>
          <p>
            Due to the global nature of the Internet, you agree to comply with
            all local rules regarding online conduct, including all laws, rules,
            codes, and regulations of the country in which you reside and the
            country from which you access this website. In addition, you agree
            to comply with all applicable laws, rules, codes, and regulations
            regarding the transmission of technical data exported from your
            jurisdiction.{" "}
          </p>
          <h3>Indemnification</h3>
          <p>
            You agree to indemnify CHuGO Pte Ltd from any claim or demand made
            by any third-party due to or arising out of your breach of these
            Terms of Service or the documents they incorporate by reference, or
            your violation of any law or the rights of a third-party.{" "}
          </p>
          <h3>Updates</h3>
          <p>
            We reserve the right to amend, vary and change these terms and
            conditions at our discretion at any time without prior notice. Each
            time you use the CHuGO Pte Ltd website, affiliated websites and
            Services(s), you should visit and review the most updated terms and
            conditions and policies that apply to your transactions and use of
            this site. If you are not agreeable with CHuGO Pte Ltd’s terms and
            conditions and policies, you agree that your sole and exclusive
            remedy is to discontinue using CHuGO Pte Ltd website, affiliated
            websites and Services(s). Your continued use of our website and
            purchases or other requests to us shall be construed as your
            acceptance of any such amendments, variations and changes to these
            terms and conditions.{" "}
          </p>
          <h3>Disclaimers</h3>
          <p>
            By using CHuGO Pte Ltd website, affiliated websites and Services(s),
            you expressly agree that such usage is at your sole risk. Neither
            CHuGO Pte Ltd nor affiliates, subsidiaries, directors, employees,
            third-party content providers, suppliers or the like (collectively,
            "Associates") warrant that use of the CHuGO Pte Ltd website,
            affiliated websites and Services(s) will be uninterrupted or
            error-free. Neither CHuGO Pte Ltd nor its Associates warrant the
            accuracy, integrity or completeness of the content, products or
            services provided on our website.{" "}
          </p>
          <p>
            Under no circumstances shall CHuGO Pte Ltd or its Associates be
            liable for any direct, indirect, incidental, special or
            consequential damages that result from your use of or inability to
            use the website, affiliated websites and Services(s), including but
            not limited to reliance by you on any information obtained from
            CHuGO Pte Ltd that results in mistakes, omissions, interruptions,
            deletion or corruption of files, viruses, delays in operation or
            transmission, or any failure of performance. You hereby acknowledge
            that this paragraph shall apply to all content, products and
            services available through the CHuGO Pte Ltd website and affiliated
            websites.{" "}
          </p>
          <h3>Termination of Use:</h3>
          <p>
            CHuGO Pte Ltd may, in its sole discretion, terminate your account or
            your use of the website, affiliated websites and Services(s) at any
            time. You are personally liable for any orders that you place or
            charges that you incur prior to the termination. We reserve the
            right to change, suspend or discontinue all or any aspects of the
            CHuGO Pte Ltd website, affiliated websites and Services(s) at any
            time without prior notice.{" "}
          </p>
          <h3>Contracts (Rights of Third Parties) Act (Cap. 53B):</h3>
          <p>
            A person who is not a party to these terms and conditions shall have
            no right under the Contracts (Rights of Third Parties) Act (Cap.53B)
            to enforce any of these terms and conditions.{" "}
          </p>
          <h3>Governing Law:</h3>
          <p>
            These terms and conditions shall be governed by the law of the
            Republic of Singapore. Both we and you irrevocably and
            unconditionally submit to the non-exclusive jurisdiction of the
            courts of Singapore.{" "}
          </p>
          <p>
            Translation. These terms and conditions may be translated into any
            other language. However, in the event of any inconsistency between
            the English language version and any translated versions, this
            English version will at all times prevail and take precedence.{" "}
          </p>
        </div>
      </div>
    );
  }
}
