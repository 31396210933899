import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { key } from "../../../services/Environment";

const RouteGuard = ({ children }) => {
    const { params } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();

    const getAuditor = () => {
        const savedLocalAuditor = localStorage.getItem("auditor");
        if (savedLocalAuditor) {
            const decrypted = CryptoJS.AES.decrypt(
                savedLocalAuditor,
                key
            ).toString(CryptoJS.enc.Utf8);

            // dispatch({ type: "setAuditor", value: JSON.parse(decrypted) });
            if (location?.pathname?.includes("auditor-login")) {
                return <Redirect to={`/attendance/${params}`} />;
            }

            return <>{children}</>;
        }

        if (location?.pathname?.includes("auditor-login")) {
            return <>{children}</>;
        }

        return <Redirect to={`/auditor-login/${params}`} />;
    };

    return getAuditor();
};

export default RouteGuard;
