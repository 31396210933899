import React from 'react';
import "./Payment.scss";
import {  Tooltip, Grid, InputLabel, TextField, InputAdornment, Box, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CustomWidthTooltip } from '../../components/CustomComponents';
import { connect } from 'react-redux';
import { Handshake } from '@mui/icons-material';
import { ApiPost } from '../../services/Api';
import Cookies from 'js-cookie';

class Payment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cardNum: '',
            cvc: '',
            companyDetails: {
                name: '',
                uen: '',
                website: ''
            }
        }
    }

    componentDidMount(){
        this.fetchCompanyDetails();
    }

    handleBack = () => {
        this.props.setActiveTab(0);
        this.props.handleErr();
    }

    handleCardNum = async (value) => {
        const new_val = await this.props.handleValid("cardNum", value)
        console.log("newval", new_val)
        this.setState({
            cardNum: new_val
        })
    }

    handleCVC = async (value) => {
        const new_val = await this.props.handleValid("cvc", value)
        console.log("newval", new_val)
        this.setState({
            cvc: new_val
        })
    }

    handleCompanyName = async (value) => {
        this.setState(prevState => ({
            companyDetails: {
                ...prevState.companyDetails,
                name: value
            }
        }));
    }

    handleUen = async (value) => {
        this.setState(prevState => ({
            companyDetails: {
                ...prevState.companyDetails,
                uen: value
            }
        }));
    }

    handleWebsite = async (value) => {
        this.setState(prevState => ({
            companyDetails: {
                ...prevState.companyDetails,
                website: value
            }
        }));
    }

    fetchCompanyDetails = async () => {
        const auth = JSON.parse(Cookies.get('auth'));

        const resp = await ApiPost('/corp/getCompanyDetails', {
            corp_id: auth.corp_id
        });

        this.setState({
            companyDetails: resp.data.data[0]?.metadata
        })
    }

    render() {
        const {
            emailErr,
            fnameErr,
            lnameErr,
            cardNumberErr,
            expirationErr,
            cvcErr,
            companyNameErr,
            uenErr
        } = this.props.error;
        const {
            cardNum,
            expiration,
            cvc,
            companyDetails
        } = this.state;

        return (
            <div id='Payment'>
                <div className='plan-head'>
                    <a href="javascript:void(0)" onClick={() => this.handleBack()}><ArrowBackIosNewIcon sx={{fontSize: 14, marginRight: 0.5}} /> Back</a>
                </div>
                <div className='plan-body'>
                    <div className='formGroup'>
                        <h3 className='plan-title'>Company Details</h3>
                        <div className='form-holder'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>Name</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    name="name"
                                    autoComplete="name"
                                    size="small"
                                    error={companyNameErr}
                                    helperText={companyNameErr}
                                    value={companyDetails?.name}
                                    onChange={(e) => this.handleCompanyName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>UEN</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="uen"
                                    error={uenErr}
                                    helperText={uenErr}
                                    value={companyDetails?.uen}
                                    onChange={(e) => this.handleUen(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>Website</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="website"
                                    value={companyDetails?.website}
                                    onChange={(e) => this.handleWebsite(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className='formGroup'>
                        <h3 className='plan-title'>Billing Address</h3>
                        <div className='form-holder'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>Email</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    name="email"
                                    autoComplete="email"
                                    size="small"
                                    error={emailErr}
                                    helperText={emailErr}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>Address Line 1</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="address1"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>Address Line 2</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="address2"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>Address Line 3</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="address3"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className='formGroup'>
                        <h3 className='plan-title'>Payment Method</h3>
                        <div className='form-holder'>
                            <Grid container spacing={2}>
                                <Grid item className='firstname' xs={6}>
                                    <InputLabel className='input-label'>First Name</InputLabel>
                                    <TextField
                                    required
                                    error={fnameErr}
                                    helperText={fnameErr}
                                    fullWidth
                                    size="small"
                                    name="fname"
                                    />
                                </Grid>
                                <Grid item className='lastname' xs={6}>
                                    <InputLabel className='input-label'>Last Name</InputLabel>
                                    <TextField
                                    required
                                    error={lnameErr}
                                    helperText={lnameErr}
                                    fullWidth
                                    size="small"
                                    name="lname"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className='input-label'>Card Number</InputLabel>
                                    <TextField
                                    required
                                    error={cardNumberErr}
                                    helperText={cardNumberErr}
                                    value={cardNum}
                                    onChange={(e) => this.handleCardNum(e.target.value)}
                                    fullWidth
                                    size="small"
                                    name="cardNumber"
                                    placeholder='1234 1234 1234 1234'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FaCcVisa style={{marginRight: 5, color: '#01082E'}} />
                                                <FaCcMastercard style={{ color: '#01082E'}} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 16,
                                    }}
                                    />
                                </Grid>
                                <Grid item className='expiration' xs={6}>
                                    <InputLabel className='input-label'>Expiration</InputLabel>
                                    <TextField
                                    required
                                    error={expirationErr}
                                    helperText={expirationErr}
                                    onChange={(e) => this.props.handleValid("expiration", e.target.value)}
                                    fullWidth
                                    size="small"
                                    name="expiration"
                                    placeholder='MM/YYYY'
                                    />
                                </Grid>
                                <Grid item className='cvc' xs={6}>
                                    <InputLabel className='input-label'>CVC</InputLabel>
                                    <TextField
                                        required
                                        error={cvcErr}
                                        helperText={cvcErr}
                                        value={cvc}
                                        fullWidth
                                        size="small"
                                        name="cvc"
                                        onChange={(e) => this.handleCVC(e.target.value)}
                                        placeholder='CVC'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CustomWidthTooltip disableFocusListener title="3-digit security code usually found on the back of your card." placement='top'><HelpOutlineIcon fontSize="small" className='info' /></CustomWidthTooltip>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item className='text' xs={12}>
                                    <InputLabel className='input-label-text'>By clicking 'Make Payment', you agree to...</InputLabel>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className='plan-footer'>
                    <Button type='submit' className='nextBtn' size="large">Make Payment</Button>
                </div>    
            </div>
        )
    }
}

const customDispatch = dispatch => {
    return {
        setActiveTab: (val) => dispatch({type: 'setActiveTab', value: val})
    }
}

export default connect(null, customDispatch)(Payment)