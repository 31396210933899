import BasePageComponent from '../components/BasePageComponent';
import "./SignUp.scss";
import { Box, Card, CardContent, Button, Divider, Typography, CardActions } from '@mui/material';
import cardValidator from 'card-validator';
import SignUpCom from './CorpsComponent/SignUpCom';
import SelectPlan from './CorpsComponent/SelectPlan';
import Payment from './CorpsComponent/Payment';
import { connect } from 'react-redux';
import { ApiGet, ApiPost } from '../services/Api';
import Cookies from 'js-cookie';
import AppLoader from '../components/AppLoader';
import { windowsHeight } from '../Globals';
import moment from 'moment/moment';


class SignUp extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            plan: 'CPro',
            selectedPlan: {},
            additional: 0,
            subTotal: 0,
            gst: 0,
            gstPercent: 0,
            baseGst: 0,
            total: 0,
            gstValPercent: 0,
            error: {},
            loading: false,
            height: 0,
            all_plan: [],
        }
    }

    componentDidMount(){
        // Cookies.remove('auth')
        this.handleHeightSize()
        this.getPlan();
        this.checkUserStatus();
        this.getGSTValue();
        this.isDateValid("12/33")
    }

    handleHeightSize = () => {
        this.setState({
            height: windowsHeight()
        })
    }

    checkUserStatus = async () => {
        let user_data = Cookies.get("auth");
        if(user_data){
            user_data = JSON.parse(user_data);
            const resp = await ApiPost('/corp/getUserActivated',{user_id: user_data.user_id});
            if(resp.data.message === "success"){
                this.props.setCorpLogin(true);
            }
        }
    }

    getGSTValue = async () => {
        const resp = await ApiGet('/corp/getGSTValue', {type: 'GST_SETTING'});

        if(resp.data.message === "success"){
            const tax_percentage = resp.data.data[0].metadata.tax_percentage;
            const gst = tax_percentage / 100;
            const { perUser, perBaseUser, number_users, base_users } = this.state.selectedPlan;
            const additional = number_users - base_users;
            const subTotal = (additional * perUser) + perBaseUser;
            const gstVal = Number((subTotal * gst).toFixed(2));
            const total = subTotal + gstVal;
            this.setState({
                gst: gstVal,
                gstPercent: gst,
                baseGst: gstVal,
                total: total,
                gstValPercent: tax_percentage,
            })
        }
        this.solveCalculation();
    }

    setLoading = async (val) => {
        console.log("value of loading",val)
        this.setState({
            loading: val
        })
    }

    getPlan = async (val = "") => {
        let title = this.state.plan;
        if(val !== ""){
            title = val;
        }else{
            const selectedPlan = Cookies.get("selectedPlan");
            if(selectedPlan){
                title = selectedPlan;
            }
        }

        try {
            const resp = await ApiPost("/getCorpPlanV2", {selected_corp_plan: title});
            const planData = resp.data.data;
            this.setState(() => ({
                plan: planData.corp_plan.plan,
                selectedPlan: planData.corp_plan,
                additional: 0,
                all_plan: planData.all_plan
            }),
            () => {
                this.getGSTValue();
            })
            
        } catch (error) {
            console.log("error", error)
        }    
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const auth = JSON.parse(Cookies.get('auth'));
        const selectedPlan = this.state.selectedPlan;
        const {additional, subTotal, gst, total, error, gstValPercent} = this.state;

        const isValid = await this.validation(data);

        if(isValid && Object.keys(error).length === 0){
            const params = {
                corp_id: auth.corp_id,
                user_id: auth.user_id,
                email: data.get('email'),
                addr1: data.get('address1'),
                addr2: data.get('address2'),
                addr3: data.get('address3'),
                fname: data.get('fname'),
                lname: data.get('lname'),
                cardNumber: data.get('cardNumber'),
                expiration: data.get('expiration'),
                cvc: data.get('cvc'),
                selectedPlan: selectedPlan,
                computation: {
                    additional: additional,
                    subTotal: subTotal,
                    gst: gst,
                    total: total,
                    gstPercent: gstValPercent
                },
                metadata: {
                    name: data.get('name'),
                    uen: data.get('uen'),
                    website: data.get('website')
                }
            }
    
            try {
                const resp = await ApiPost('/corp/payment', params);
                if(resp.data.data){
                    window.location.href = resp.data.data;
                }
            } catch (error) {
                console.log("error", error)
            }
        }
    }

    validation = async (data) => {
        let status = true, err = {};
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const expiration_regex = /^(0[1-9]|1[0-2])\/([0-9]{4})$/;
        const cvc_regex = /^[0-9]{3}$/;
        const email = data.get('email');
        const fname = data.get('fname');
        const lname = data.get('lname');
        const cardNumber = data.get('cardNumber');
        const expiration = data.get('expiration');
        const cvc = data.get('cvc');
        const companyNameErr = data.get('name')
        const uenErr = data.get('uen')

        const card = cardValidator.number(cardNumber)
        const dateValid = this.isDateValid(expiration);

        if(companyNameErr === ""){
            err = {...err, companyNameErr: "Please fill up the field."}
            status = false
        }

        if(uenErr === ""){
            err = {...err, uenErr: "Please fill up the field."}
            status = false
        }

        if(email === "" || !email.match(validRegex)){
            err = {...err, emailErr: email === "" ? "Please fill up the field." : "Please enter a valid email."}
            status = false
        }

        if(fname === ""){
            err = {...err, fnameErr: "Please fill up the field."}
            status = false
        }

        if(lname === ""){
            err = {...err, lnameErr: "Please fill up the field."}
            status = false
        }

        if(cardNumber === "" || !card.isValid){
            err = {...err, cardNumberErr: cardNumber === "" ? "Please fill up the field." : 'Please enter a valid card number.' }
            status = false
        }

        if(expiration === "" || expiration_regex.test(expiration) === false || expiration_regex.test(expiration) === true && !dateValid){
            err = {...err, expirationErr: expiration === "" ? "Please fill up the field." : "Please enter a valid expiration date."}
            status = false
        }

        if(cvc === "" || !cvc.match(cvc_regex)){
            err = {...err, cvcErr: cvc === "" ? "Please fill up the field." : 'Please enter a valid CVC.'}
            status = false
        }

        this.setState({
            error: err
        })
        return status
    }

    handleSelectedPlan = async (val) => {
        await this.getPlan(val.title);
    }

    handleAddUser = () => {
        this.setState((state) => ({
            selectedPlan: {
                ...state.selectedPlan,
                number_users: state.selectedPlan?.number_users + 1
            },
        }),
        () => {
            this.solveCalculation();
        })
    }

    handleMinusUser = () => {
        this.setState((state) => ({
            selectedPlan: {
                ...state.selectedPlan,
                number_users: state.selectedPlan?.number_users > state.selectedPlan?.base_users ? state.selectedPlan?.number_users - 1 : state.selectedPlan?.base_users
            },
        }),
        () => {
            this.solveCalculation();
        })
    }


    solveCalculation = () => {
        const { perUser, perBaseUser, number_users, base_users} = this.state.selectedPlan;
        const gstDefault = this.state.baseGst;
        const gstPercent = this.state.gstPercent;
        const additional = number_users - base_users;
        const subTotal = (additional * perUser) + perBaseUser;
        const gst = gstDefault + Number(((perUser * gstPercent) * additional).toFixed(2));
        const total = subTotal + gst;

        // console.log('------------------')
        // console.log('number_users', number_users)
        // console.log('base_users', base_users)
        // console.log('perBaseUser', perBaseUser)
        // console.log('gstDefault', gstDefault)
        // console.log('gst', gst)
        // console.log('subTotal', subTotal)

        // console.log('------------------>>')
        // console.log('perUser', perUser)
        // console.log('gstPercent', gstPercent)
        // console.log('additional', additional)


        this.setState({
            additional: additional ? additional : 0,
            subTotal: subTotal ? subTotal : 0,
            gst: gst ? gst : 0,
            total: total ? total : 0
        })
    }

    handleErr = () => {
        this.setState({
            error: {}
        })
    }

    handleValid = async (fieldName, value) => {
        const characterReg = /^[0-9]*$/;
        const cardNum_regex = /^[0-9]{15,16}$/;
        const expiration_regex = /^(0[1-9]|1[0-2])\/([0-9]{4})$/;
        const cvc_regex = /^[0-9]{3}$/;
        const {error} = this.state;
        let err = {...error};

        switch (fieldName) {
            case 'cardNum':
                const valid = cardValidator.number(value)
                if(!valid.isValid){
                    err = {...err, cardNumberErr: 'Please enter a valid card number.'}
                }else{
                    delete err.cardNumberErr
                }
                break;
            case 'cvc':
                if(!value.match(cvc_regex)){
                    err = {...err, cvcErr: 'Please enter a valid CVC.'}
                }else{
                    delete err.cvcErr
                }
                break;
            default:
                const dateValid = this.isDateValid(value);
                if(expiration_regex.test(value) === false || expiration_regex.test(value) === true && !dateValid){
                    err = {...err, expirationErr: "Please enter a valid expiration date."}
                }else{
                    delete err.expirationErr;
                }
                break;
        }

        this.setState({
            error: err
        })
        return value;
    }

    isDateValid = (date) => {
        const MMYY = date.split("/");
        const year = moment().format("YYYY");
        if(MMYY[0] <= 12 && MMYY[1] >= year){
            return true;
        }else{
            return false;
        }
    }


    render() {
        const isRegister = this.props.isRegister;
        const activeTab = this.props.activeTab;
        const title = this.state.plan;
        const loading = this.state.loading;
        
        const {additional, subTotal, gst, total, selectedPlan} = this.state;
        const { perUser, perBaseUser, number_users, base_users} = selectedPlan;
        const height = this.state.height;

        return super.render(
            <div id='SignUp' className={activeTab === 0 ? 'selected' : null} style={{minHeight: height}}>
                <div className='container'>
                    
                        {
                            !isRegister ? <SignUpCom setLoading={this.setLoading} /> : (
                                <Box component="form" className='billForm' noValidate onSubmit={(event) => this.handleSubmit(event)}>
                                    <Card className='mainCard'>
                                        <CardContent className='card-body'>
                                            <div className='holder plan'>
                                                {
                                                    activeTab === 0 ? <SelectPlan data={{plan: this.state.plan, selectedPlan: this.state.selectedPlan, all_plan: this.state.all_plan }} handleSelectedPlan={this.handleSelectedPlan} handleAddUser={this.handleAddUser} handleMinusUser={this.handleMinusUser}  /> : <Payment error={this.state.error} handleError={this.handleErr} handleValid={(field, val) => this.handleValid(field, val)}  />
                                                }
                                            </div>
                                            <div className='holder summary'>
                                                <h4 className='summary-title'>Summary</h4>
                                                <Divider />
                                                <div className='summary-holder'>
                                                    <h3 className='planTitle'>{title} Plan ({number_users} users)</h3>
                                                    <h4 className='plan-bill'>Billed annually</h4>
                                                </div>
                                                <div className='summary-holder'>
                                                    <div className='inner-holder'>
                                                        <Typography className="text-label">Base Plan ({base_users} users)</Typography>
                                                        <Typography className="text-value">SGD${Number(perBaseUser).toFixed(2)}</Typography>
                                                    </div>
                                                    <div className='inner-holder'>
                                                        <Typography className="text-label">Additional Users ({additional} user)</Typography>
                                                        <Typography className="text-value">SGD${Number(perUser).toFixed(2)}</Typography>
                                                    </div>
                                                </div>
                                                <Divider />
                                                <div className='summary-holder'>
                                                    <div className='inner-holder'>
                                                        <Typography className="text-label">Subtotal</Typography>
                                                        <Typography className="text-value">SGD${Number(subTotal).toFixed(2)}</Typography>
                                                    </div>
                                                    <div className='inner-holder'>
                                                        <Typography className="text-label">GST {this.state.gstValPercent}%</Typography>
                                                        <Typography className="text-value">SGD${Number(gst).toFixed(2)}</Typography>
                                                    </div>
                                                </div>
                                                <Divider />
                                                <div className='summary-holder'>
                                                    <div className='inner-holder'>
                                                        <Typography className='total'>Total</Typography>
                                                        <Typography className="text-value">SGD${Number(total).toFixed(2)}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions className='mainCardFooter'>
                                            {
                                                activeTab === 0 ? <a href="javascript:void(0)" className='processBtn next' onClick={() => this.props.setActiveTab(1)} size="large">Next</a> : <Button className='processBtn' type="submit" size="large">Make Payment</Button>
                                            }
                                        </CardActions>
                                    </Card>
                                </Box>
                            )
                        }
                    {
                        loading ? <AppLoader /> : null
                    }
                </div>
            </div>
        )
    }
}

const customState = state => {
    return{
        isRegister: state.isCorpLogin,
        activeTab: state.activeTab,
        billStatus: state.billStatus
    }
}

const customDispatch = dispatch => {
    return{
        setCorpLogin: (val) => dispatch({type: 'setCorpLogin', value: val}),
        setActiveTab: (val) => dispatch({type: 'setActiveTab', value: val}),
        setBillStatus: (val) => dispatch({type: 'setBillStatus', value: val})
    }
}

export default connect(customState, customDispatch)(SignUp)