import {
    Box,
    Button,
    Container,
    TextField,
    ThemeProvider,
    Typography,
    createTheme,
    Theme,
    useTheme,
    CircularProgress,
    Snackbar,
    Alert,
    Slide,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";
import moment from "moment";
import { getEnvVars, key } from "../../../services/Environment";
import { useParams, useHistory } from "react-router-dom";
import classes from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import axios from "axios";

const LexisEVENTLogo = () => (
    <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
    >
        <Typography variant="h4" sx={{ mr: "4px" }}>
            Le
        </Typography>
        <img
            src="/assets/images/LexisEVENT.svg"
            style={{
                width: "50px",
            }}
            alt="Logo"
        />
        <Typography variant="h4" sx={{ ml: "4px" }}>
            is
        </Typography>
    </Box>
);

const defaultValues = {
    password: "",
    name: "",
};

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#E0E3E7",
                        "--TextField-brandBorderHoverColor": "#B2BAC2",
                        "--TextField-brandBorderFocusedColor": "#6F7E8C",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor:
                                "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]:
                            {
                                borderColor:
                                    "var(--TextField-brandBorderFocusedColor)",
                            },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            borderBottom:
                                "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom:
                                "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom:
                                "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
        },
    });

const AuditorLogin = ({ auditor }) => {
    const { params } = useParams();
    const history = useHistory();
    const theme = useTheme();
    const { apiUrl } = getEnvVars();
    const [submitting, setSubmitting] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [screen, setScreen] = useState("password");
    const dispatch = useDispatch();
    const [loggedAuditor, setLoggedAuditor] = useState({});

    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useForm({
        defaultValues,
    });

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const setToast = (message, severity) => {
        setSnackbarMessage(message);
        setSeverity(severity);
        setOpenSnackbar(true);
    };

    const onSubmitOTP = async (data) => {
        setSubmitting(true);
        try {
            const { data: response } = await axios.post(
                `/web/event/verify-otp`,
                { ...data, email: getValues("email") },
                {
                    baseURL: apiUrl,
                    timeout: 20000,
                }
            );

            if (response?.message === "fail") {
                setToast(`OTP does not match.`, "error");
            } else if (response?.message === "expired") {
                setToast(`OTP already expired.`, "error");
                setScreen("email");
            } else {
                const auditorData = CryptoJS.AES.encrypt(
                    JSON.stringify(loggedAuditor).toString(),
                    key
                ).toString();

                dispatch({ type: "setAuditor", value: auditorData });
                localStorage.setItem("auditor", auditorData);
                history.push(`/attendance/${params}`);
            }
            setSubmitting(false);
        } catch (error) {
            console.log("Error on verifying email: ", error?.message);
            setSubmitting(false);
            setToast(`Unable to login. ${error?.message}`, "error");
        }
    };

    const onSubmitEmail = async (data) => {
        setSubmitting(true);
        const event_id = params;
        try {
            const { data: response } = await axios.post(
                `/web/event/login`,
                { ...data, event_id },
                {
                    baseURL: apiUrl,
                    timeout: 20000,
                }
            );

            if (response?.message === "fail") {
                setToast(
                    `Unable to login. ${
                        response?.data?.message || response?.data
                    }`,
                    "error"
                );
            } else {
                setLoggedAuditor(response?.data);
                setToast(`An OTP was sent to your email.`, "success");
                setScreen("otp");
                setValue("otp", "");
            }
            setSubmitting(false);
        } catch (error) {
            console.log("Error on verifying email: ", error?.message);
            setSubmitting(false);
            setToast(`Unable to login. ${error?.message}`, "error");
        }
    };

    const loginV2 = async (data) => {
        setSubmitting(true);
        const event_id = params;
        try {
            const { data: response } = await axios.post(
                `/web/event/login-v2`,
                { ...data, event_id },
                {
                    baseURL: apiUrl,
                    timeout: 20000,
                }
            );

            if (response?.message === "fail") {
                setToast(
                    `Unable to login. ${
                        response?.data?.message || response?.data
                    }`,
                    "error"
                );
            } else {
                setLoggedAuditor(response?.data);
                setScreen("name");
                setValue("name", "");
            }
            setSubmitting(false);
        } catch (error) {
            console.log("Error on verifying password: ", error?.message);
            setSubmitting(false);
            setToast(`Unable to login. ${error?.message}`, "error");
        }
    };

    const onSubmitName = async (data) => {
        setSubmitting(true);
        setScreen("password");

        const auditorData = CryptoJS.AES.encrypt(
            JSON.stringify({
                ...loggedAuditor, name: data?.name 
            }).toString(),
            key
        ).toString();

        dispatch({ type: "setAuditor", value: auditorData });
        localStorage.setItem("auditor", auditorData);
        history.push(`/attendance/${params}`);
    };

    return (
        <Box className={classes.loginWrap}>
            <Container
                maxWidth="sm"
                sx={{
                    color: "#fff",
                    height: "100%",
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Slide
                    direction="right"
                    mountOnEnter
                    in={screen === "password"}
                    unmountOnExit
                >
                    <Box
                        component={"form"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        sx={(theme) => ({
                            width: "300px",
                            [theme.breakpoints.down("sm")]: {
                                width: "100%",
                                padding: "0 24px",
                            },
                        })}
                        noValidate
                        onSubmit={handleSubmit(loginV2)}
                    >
                        <LexisEVENTLogo />
                        <Typography variant="h6" sx={{ mt: 1 }}>
                            Event Auditor Login
                        </Typography>

                        <Box sx={{ mt: 5, width: "100%" }}>
                            <ThemeProvider theme={customTheme(theme)}>
                                <Controller
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Password is required.",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            autoComplete="off"
                                            type="password"
                                            id="filled-basic"
                                            label="Password"
                                            variant="outlined"
                                            InputProps={{
                                                style: { color: "#fff" },
                                            }}
                                            InputLabelProps={{
                                                style: { color: "#fff" },
                                            }}
                                            error={!!errors.password}
                                            helperText={
                                                errors?.password?.message ?? " "
                                            }
                                        />
                                    )}
                                    control={control}
                                    name="password"
                                />
                            </ThemeProvider>
                        </Box>
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"center"}
                            sx={{ width: "100%", mt: 2 }}
                        >
                            <Button
                                size="large"
                                type={"submit"}
                                fullWidth
                                variant="contained"
                                sx={{ textTransform: "none" }}
                            >
                                <Box
                                    display={"flex"}
                                    flex={1}
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent={"center"}
                                >
                                    {submitting && (
                                        <CircularProgress
                                            size={16}
                                            sx={{ mr: 1 }}
                                        />
                                    )}
                                    <span>
                                        {submitting
                                            ? "Validating..."
                                            : "Log in"}
                                    </span>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Slide>

                <Slide
                    direction="left"
                    mountOnEnter
                    in={screen === "name"}
                    unmountOnExit
                >
                    <Box
                        component={"form"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        sx={(theme) => ({
                            width: "300px",
                            [theme.breakpoints.down("sm")]: {
                                width: "100%",
                                padding: "0 24px",
                            },
                        })}
                        noValidate
                        onSubmit={handleSubmit(onSubmitName)}
                    >
                        <LexisEVENTLogo />
                        <Typography variant="h6" sx={{ mt: 1 }}>
                            Enter your Name
                        </Typography>

                        <Box sx={{ mt: 5, width: "100%" }}>
                            <ThemeProvider theme={customTheme(theme)}>
                                <Controller
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Name is required.",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            id="filled-basic"
                                            label="Name"
                                            variant="outlined"
                                            InputProps={{
                                                inputProps: {
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                style: { color: "#fff" },
                                            }}
                                            InputLabelProps={{
                                                style: { color: "#fff" },
                                            }}
                                            error={!!errors.name}
                                            helperText={
                                                errors?.name?.message ?? " "
                                            }
                                        />
                                    )}
                                    control={control}
                                    name="name"
                                />
                            </ThemeProvider>
                        </Box>
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"center"}
                            sx={{ width: "100%", mt: 2 }}
                        >
                            <Button
                                size="large"
                                variant="contained"
                                type={"submit"}
                                fullWidth
                                sx={{ textTransform: "none" }}
                            >
                                <Box
                                    display={"flex"}
                                    flex={1}
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent={"center"}
                                >
                                    {submitting && (
                                        <CircularProgress
                                            size={16}
                                            sx={{ mr: 1 }}
                                        />
                                    )}
                                    <span>
                                        {submitting
                                            ? "Please wait..."
                                            : "Proceed"}
                                    </span>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Slide>
            </Container>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openSnackbar}
                autoHideDuration={9000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        auditor: state.auditor,
    };
};

export default connect(mapStateToProps)(AuditorLogin);
