import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Voucher from "./pages/Voucher";
import SignUp from "./pages/SignUp";
import Result from "./pages/Result";
import Activate from "./pages/Activate";
import Verification from "./pages/Verification";
import Profile from "./pages/Profile";
import Dashboard from "./pages/LexisEvent/Dashboard";
import AuditorLogin from "./pages/LexisEvent/Login";
import RouteGuard from "./pages/LexisEvent/RouteGuard";
import ContactUs from "./pages/ContactUs";

export default function Routes() {
  return (
    <Switch>
      <Route path="/auditor-login/:params?">
        <RouteGuard>
          <AuditorLogin />
        </RouteGuard>
      </Route>
      <Route path="/attendance/:params">
        <RouteGuard>
          <Dashboard />
        </RouteGuard>
      </Route>
      <Route path="/Corp/verification">
        <Verification />
      </Route>
      <Route path="/Corp/Activate">
        <Activate />
      </Route>
      <Route path="/Corp/Result">
        <Result />
      </Route>
      {/* <Route path="/Corp/SignUp">
        <SignUp />
      </Route> */}
      <Route path="/voucher">
        <Voucher />
      </Route>
      <Route path="/PrivacyPolicy">
        <PrivacyPolicy />
      </Route>
      <Route path="/TermsConditions">
        <TermsConditions />
      </Route>
      <Route path="/ContactUs">
        <ContactUs />
      </Route>
      <Route path="/Profile">
        <Profile />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}
