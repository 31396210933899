import React from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid, TextField, FormLabel, CardHeader, Typography, InputLabel, InputAdornment, IconButton } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Cookies from 'js-cookie';
import "./SignUpCom.scss";
import { connect } from 'react-redux';
import { ApiPost } from '../../services/Api';
import AppLoader from '../../components/AppLoader';



class SignUpCom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPassword: false,
            showConfirmPassword: false,
            error: {},
            loading: false
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        // setTimeout(() => {
        //     Cookies.set('auth', JSON.stringify({email: "asdfasdf", corp_id: "Aasdfasdf"}), {
        //         // expires: 1/48
        //         expires: 1
        //     })
        //     this.props.setCorpLogin(true);
        //     this.props.setLoading(false);
        // }, 1000);
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        const confirm_password = data.get('confirmPassword');
        const isValid = await this.validation(confirm_password, password, email);
        if(isValid){
            this.props.setLoading(true);
            const params = {
                email: email,
                password: password,
            }
            
            try {
                const resp = await ApiPost("/corp/registration", params);
                const message = resp.data.message;
                if(message === "success" || message === "hasEmail" || message === "email_failed" || message === "pre_registered"){
                    const email = resp.data.data.email;
                    const corp_id = resp.data.data.corp_id;
                    const user_id = resp.data.data.user_id;
                    Cookies.set('auth', JSON.stringify({email: email, corp_id: corp_id, user_id: user_id}), {
                        // expires: 1/48
                        expires: 0.04
                    })
                    if(message === "success" || message === "email_failed" || message === "hasEmail"){
                        if(message === "email_failed"){
                            this.props.setVerStatus(true);
                        }
                        window.location.href = "/Corp/Verification";
                    }else{
                        this.props.setCorpLogin(true);
                    }
                }else{
                    if(message === "Email already exist!"){
                        this.setState({
                            error: {
                                emailErr: message
                            }
                        })
                    }
                }
                this.props.setLoading(false);
                
            } catch (error) {
                console.log("error", error)
            }
        }
    }

    setLoading = (val) => {
        this.setState({
            loading: val
        })
    }

    validation = async (confirm_password, password, email) => {
        let status = true, error = {};
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var regularExpression =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_+=./'`~|#^:;",])[A-Za-z\d@$!%*?&\-_+=./'`~|#^:;",]{8,}$/;
        if(email === "" || !email.match(validRegex)){
            error = {
                ...error,
                emailErr: "Please enter a valid email."
            }
            status = false
        }

        if(confirm_password === "" || confirm_password !== password){
            error = {
                ...error,
                confirmPassErr: "Please make sure your pasword match."
            }
            status = false
        }
        if( !regularExpression.test(password)){
            error = {
                ...error,
                passwordErr: "Password did not meet the minimun requirement."
            }
            status = false
        }

        this.setState({
            error: error
        })

        return status;
    }

    handleClickShowPassword = () => {
        this.setState((state) => ({
            showPassword: !state.showPassword
        }))
    }

    handleClickShowConfirmPassword = () => {
        this.setState((state) => ({
            showConfirmPassword: !state.showConfirmPassword
        }))
    }

    render() {
        const showPassword = this.state.showPassword;
        const showConfirmPassword = this.state.showConfirmPassword;
        const { confirmPassErr, passwordErr, emailErr } = this.state.error;
        const loading = this.state.loading;
        return (
            <div id='SignUpCom'>
                <Card className='signUpCard' sx={{ maxWidth: 500 }}>
                    <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
                        <CardHeader
                            title="Register for An Account"
                            titleTypographyProps={{
                                fontFamily: 'Inter',
                                fontSize: 20,
                                fontWeight: '700',
                                letterSpacing: 0,
                                textAlign: 'left',
                                color: '#000000'
                            }}
                            />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel className={emailErr ? "inputLabel errorLabel" : "inputLabel"}>Email</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    name="email"
                                    autoComplete="email"
                                    size="small"
                                    error={emailErr}
                                    helperText={emailErr}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className={passwordErr ? "inputLabel errorLabel" : "inputLabel"}>Password</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowPassword()}
                                                edge="end"
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={passwordErr}
                                    helperText={passwordErr}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel className='formInfo'>Ensure password has a minimum of 8 Characters, with at least a symbol, upper and lower case letters and a number.</FormLabel >
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className={confirmPassErr ? "inputLabel errorLabel" : "inputLabel"}>Confirm Password</InputLabel>
                                    <TextField
                                    required
                                    fullWidth
                                    className={confirmPassErr ? "errorInput" : null}
                                    name="confirmPassword"
                                    size="small"
                                    type={showConfirmPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowConfirmPassword()}
                                                edge="end"
                                                >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),

                                    }}
                                    error={confirmPassErr}
                                    helperText={confirmPassErr}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end'}}>
                            <Button type="submit" className='registerBtn' size="large">Register</Button>
                        </CardActions>
                    </Box>
                </Card>
                {
                    loading ? <AppLoader /> : null
                }
            </div>
        )
    }
}

const customState = state => {
    return {
        verify: state.verify
    }
}

const customDispatch = (dispatch) => {
    return{
        setCorpLogin: (val) => dispatch({type: 'setCorpLogin', value: val}),
        setVerStatus: (val) => dispatch({type: 'setVerStatus', value: val}),
    }
}

export default connect(customState, customDispatch)(SignUpCom);