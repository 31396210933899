import React, { Component} from 'react';
import './Increament.scss';
import { Box, Button, TextField, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export default class Increament extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            num: 0
        }
    }
    async componentDidMount () {

    }

    onMinus = () => {
        this.setState((state) => ({
            num: state.num > 0 ? state.num - 1 : 0
        }))
    }

    onAdd = () => {
        this.setState((state) => ({
            num: state.num + 1
        }))
    }
    

    render(){
        const {handleMinus, handleAdd, number} = this.props;
        const default_val = number ? number : this.state.num;
        return (
            <Box component="div" className='AppIncreament'>
                <Button size="large" onClick={handleMinus ? handleMinus : this.onMinus }><RemoveIcon /></Button>
                <Typography>{default_val}</Typography>
                <Button size="large" onClick={handleAdd ? handleAdd : this.onAdd}><AddIcon /></Button>
            </Box>
        )
    }
}