import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Snackbar,
    Typography,
    colors,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import CryptoJS from "crypto-js";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import QRScanModal from "../../../components/LexisEvent/QRScanModal";
import { ApiGet, ApiPost } from "../../../services/Api";
import { getEnvVars, key } from "../../../services/Environment";
import classes from "./styles.module.scss";
import AttendanceCard from "../../../components/LexisEvent/AttendanceCard";
import axios from "axios";
import AlertDialog from "../../../components/LexisEvent/AlertDialog";
import UiCard from "../../../components/LexisEvent/ui/Card";
import { BrokenImage, EventNote, Image } from "@mui/icons-material";
import { connect, useDispatch } from "react-redux";

const NoAttendance = ({ isDownMd }) => (
    <Card
        elevation={3}
        sx={{
            bgcolor: "#04081ea3",
            mb: 2,
            color: "#fff",
        }}
    >
        <CardContent
            sx={(theme) => ({
                p: 4,
                [theme.breakpoints.up("md")]: {
                    px: 8,
                },
            })}
        >
            <Typography
                variant={"h6"}
                component="div"
                sx={{
                    color: "rgba(255,255,255,0.8)",
                    textAlign: "center",
                    fontSize: "16px",
                }}
            >
                No attendance yet.
            </Typography>
        </CardContent>
    </Card>
);

const Dashboard = ({ auditor }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const [openQR, setOpenQR] = useState(false);
    const { params } = useParams();
    const [eventId, setEventId] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [attendance, setAttendance] = useState([]);
    const [fetchingAttendance, setFetchingAttendance] = useState(false);
    const [username,setUserName] = useState();
    const { apiUrl } = getEnvVars();
    const [expiredState, setExpiredState] = useState({
        status: false,
        message: "",
    });
    const [alertState, setAlertState] = useState({
        open: false,
        title: "",
        message: "",
        buttonCaption: "Okay, I understand.",
        callback: null,
    });
    const [event, setEvent] = useState({});

    const handleCloseQR = () => setOpenQR(false);

    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const setToast = (message, severity) => {
        setSnackbarMessage(message);
        setSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleScan = async (result) => {
        const qrContent = JSON.parse(decrypt(result));
        if (qrContent?.event_id === eventId) {
            await saveAttendance(qrContent);
            handleCloseQR();
        } else {
            setToast("Invalid QR Code detected.", "error");
            return;
        }
    };

    const decrypt = (data) => {
        return CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
    };

    const getEventId = () => {
        const decrypted = decrypt(decodeURIComponent(params));
        if (!decrypted) {
            setToast("Cannot identify Event.", "error");
            return;
        }
        setEventId(decrypted);
        getAttendance(decrypted, true);
    };

    const saveAttendance = async (ticket) => {
        try {
            const _auditor =
                typeof auditor === "string"
                    ? JSON.parse(decrypt(auditor))
                    : auditor;
            const { data } = await axios.post(
                `/web/event/save-attendance`,
                {
                    ...ticket,
                    scanned_by: _auditor?.name || "---",
                },
                {
                    baseURL: apiUrl,
                    timeout: 20000,
                }
            );

            if (data?.message === "exist") {
                setToast("Already scanned.", "error");
                return;
            }

            handleCloseQR();
            getAttendance(eventId);
            setToast("Scan successful.", "success");
        } catch (error) {
            console.log("Error on saving attendance due to: ", error?.message);
        }
    };

    const logout = () => {
        dispatch({ type: "setAuditor", value: null });
        localStorage.removeItem("auditor");
        history.push(`/auditor-login/${params}`);
    };

    const getAttendance = useCallback(
        async (id = eventId, showLoader = false) => {
            showLoader && setFetchingAttendance(true);
            try {
                const { data } = await ApiGet(`/web/event/attendance/${id}`);
                const {name, list, invalid_dates, message, event } = data?.data;

                if (invalid_dates) {
                    setExpiredState({ status: true, message });
                    setAlertState((prevState) => ({
                        ...prevState,
                        open: true,
                        title: "Opps!",
                        message: `${message}`,
                        callback: logout
                    }));
                    setFetchingAttendance(false);
                    return;
                }
                setUserName(name)
                setEvent(event || {});
                setAttendance(list || []);
                setFetchingAttendance(false);
            } catch (error) {
                setFetchingAttendance(false);
                console.log(
                    "Error on fetching attendance due to: ",
                    error?.message
                );
            }
        },
        []
    );

    const handleCloseAlert = () => {
        setAlertState((prevState) => ({
            ...prevState,
            open: false,
        }));

        alertState.callback && alertState.callback();
    };

    const checkLogin = async (email = "") => {
        try {
            console.log({
                event_id: params || "",
                email,
            });
            const { data } = await axios.post(
                `/web/event/check-login`,
                {
                    event_id: params || "",
                    email,
                },
                {
                    baseURL: apiUrl,
                    timeout: 20000,
                }
            );

            console.log(data);
        } catch (error) {
            console.log("Error on saving attendance due to: ", error?.message);
        }
    };

    useEffect(() => {
        if (!auditor) {
            const localStorageAudtior = localStorage.getItem("auditor");
            const decrypted = JSON.parse(decrypt(localStorageAudtior));
            dispatch({
                type: "setAuditor",
                value: decrypted,
            });

            // (async () => {
            //     await checkLogin(decrypted?.email);
            // })();
        }

        getEventId();
    }, []);

    return (
        <Box className={classes.mainWrap}>
            <Container maxWidth="sm">
                <QRScanModal
                    open={openQR}
                    handleScan={handleScan}
                    handleClose={handleCloseQR}
                    setOpen={setOpenQR}
                />

                <Box display={"flex"} flex={1} flexDirection={"column"}>
                    <Box
                        display={"flex"}
                        flex={1}
                        flexDirection={"column"}
                        alignItems={"center"}
                        sx={{
                            px: "8px",
                        }}
                    >
                        <Box
                            display={"flex"}
                            className={classes.imgContainer}
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                mb: "16px",
                                mt: "32px",
                            }}
                        >
                            <Typography
                                sx={{ color: "#fff", fontSize: "22px", mr: 1 }}
                            >
                                LexisEVENT
                            </Typography>
                            <img
                                src="/assets/images/LexisEVENT.svg"
                                style={{
                                    width: "60px",
                                    paddingBottom: "16px",
                                }}
                                alt="Logo"
                            />

                            <Typography
                                sx={{ color: "#fff", fontSize: "22px", ml: 1 }}
                            >
                                Management
                            </Typography>
                        </Box>

                        <UiCard>
                            <Box
                                flex={1}
                                display={"flex"}
                                flexDirection={"row"}
                            >
                                <Box
                                    sx={(theme) => ({
                                        width: "130px",
                                        height: "130px",
                                        marginRight: "16px",
                                        background: "#5f5a5a21",
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        ...(event?.images && {
                                            backgroundImage: `url(${event?.images[0]})`,
                                        }),
                                        [theme.breakpoints.up("sm")]: {
                                            width: "140px",
                                            height: "140px",
                                        },
                                    })}
                                >
                                    {!event?.images && (
                                        <>
                                            <Image
                                                sx={{
                                                    fontSize: "70px",
                                                    color: "#767070f0",
                                                }}
                                            />
                                            <Typography
                                                style={{
                                                    textAlign: "center",
                                                    padding: "0 16px",
                                                    color: "#7b7b7b",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                No image available
                                            </Typography>
                                        </>
                                    )}
                                </Box>

                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    sx={(theme) => ({
                                        [theme.breakpoints.up("sm")]: {
                                            mt: 1,
                                        },
                                    })}
                                    flex={1}
                                >
                                    <Typography
                                        sx={(theme) => ({
                                            marginLeft: "4px",
                                            fontSize: "16px",
                                            [theme.breakpoints.up("sm")]: {
                                                fontSize: "18px",
                                            },
                                        })}
                                    >
                                        {!event || expiredState.status
                                            ? "No Event to show"
                                            : event?.title}
                                    </Typography>
                                    <Box
                                        sx={{ mt: "12px" }}
                                        display={"flex"}
                                        flexDirection={"row"}
                                    >
                                        <Box
                                            sx={{
                                                heigth: "20px",
                                                width: "20px",
                                                mr: 1,
                                            }}
                                            display={"flex"}
                                            flexDirection={"row"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <Box
                                                sx={{
                                                    height: "12px",
                                                    width: "12px",
                                                    borderRadius: "50%",
                                                    bgcolor:
                                                        !event ||
                                                        expiredState.status
                                                            ? colors.red.A200
                                                            : "#53D732",
                                                }}
                                            />
                                        </Box>

                                        <Typography
                                            sx={{
                                                fontSize: "16px",
                                                color:
                                                    !event ||
                                                    expiredState.status
                                                        ? colors.red.A200
                                                        : "#53D732",
                                            }}
                                        >
                                            {!event || expiredState.status
                                                ? "Inactive Event"
                                                : "Active Event"}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{ mt: "8px" }}
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                    >
                                        <EventNote
                                            fontSize="small"
                                            sx={{ mr: 1, color: "#fff" }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "#8F9EB6",
                                            }}
                                        >
                                            {!event || expiredState.status
                                                ? "No event date to show."
                                                : `${moment(
                                                      event?.date_start
                                                  ).format("ddd, MMM.DD")} - 
                                                    ${moment(
                                                        event?.date_end
                                                    ).format("ddd, MMM.DD hhA")}
                                                    ${moment(
                                                        event?.date_end
                                                    ).format("Z")}`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </UiCard>

                        <Typography
                            sx={{
                                fontSize: "22px",
                                color: "#fff",
                                mb: "8px",
                                mt: "8px",
                            }}
                        >
                            Scan QR Code
                        </Typography>
                        {/* BUTTON */}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (expiredState.status) {
                                    setAlertState((prevState) => ({
                                        ...prevState,
                                        open: true,
                                        title: "Opps!",
                                        message: `${expiredState.message}`,
                                    }));
                                    return;
                                }
                                setOpenQR(true);
                            }}
                            size="large"
                            sx={{
                                fontSize: "16px",
                                px: "12px",
                                py: "16px",
                                textTransform: "none",
                                bgcolor: "#2964F6",
                            }}
                        >
                            Continue
                        </Button>
                    </Box>

                    {/* DIVIDER */}
                    <Box
                        sx={{ height: "1px", bgcolor: "#2E3B56", my: "32px" }}
                    />

                    <Typography
                        variant="h6"
                        color={"#fff"}
                        sx={{
                            textAlign: "center",
                            mb: "8px",
                            fontSize: "16px",
                        }}
                    >
                        Attendance ({(attendance || [])?.length})
                    </Typography>
                </Box>
            </Container>

            <Container
                sx={{
                    height: "100%",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                        width: "0.4em",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}
                maxWidth="sm"
                disableGutters={true}
            >
                {fetchingAttendance && (
                    <Box
                        display={"flex"}
                        flex={1}
                        flexDirection={"column"}
                        alignItems={"center"}
                    >
                        <CircularProgress size={40} />
                        <Typography color={"#fff"} sx={{ mt: 1 }}>
                            Loading...
                        </Typography>
                    </Box>
                )}

                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                        px: 3,
                    }}
                >
                    {!fetchingAttendance && attendance?.length <= 0 && (
                        <NoAttendance isDownMd={isDownMd} />
                    )}

                    {(attendance || [])?.length > 0 &&
                        (attendance || [])?.map((row, i) =>{
                            return (
                                <AttendanceCard
                                    key={row.id}
                                    row={row}
                                    isDownMd={isDownMd}
                                />
                            )
                        })}
                </Box>
            </Container>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openSnackbar}
                autoHideDuration={9000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={severity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <AlertDialog
                open={alertState.open}
                handleClose={handleCloseAlert}
                message={alertState.message}
                title={alertState.title}
                buttonCaption={alertState.buttonCaption}
                containerStyles={{
                    backgroundColor: "#111621",
                }}
                titleStyles={{
                    color: "#fff",
                }}
                contentStyles={{
                    color: "#fff",
                }}
                buttonStyles={{
                    color: "#03A9F4",
                }}
            />

            {/* <Button
                onClick={() => {
                    dispatch({ type: "setAuditor", value: null });
                    localStorage.removeItem("auditor");
                    history.push(`/auditor-login/${params}`);
                }}
            >
                Log out
            </Button> */}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        auditor: state.auditor,
    };
};

export default connect(mapStateToProps)(Dashboard);
