import React, { Component } from "react";
import "./Footer.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
export default class PageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '',
      isMobile: false
    };
  }

  async componentDidMount() {
    const url = window.location.pathname;
    const width = window.innerWidth;
    this.setState({
      path: url,
      isMobile: width <= 990 ? true : false
    })
  }

  handleWhatsApp = (val) => {
    window.open(val, "_blank")
  }

  handleLinkClick = () => {
    document.dispatchEvent(new Event('app_download_buttons'));
  }

  render() {
    const isVoucher = this.state.path.includes("/voucher");
    return (
      <>
        <footer id="footer" className="Footer">
          <div className="lexisLogo">
            <img
              className="lexiscard"
              src="../../assets/images/LexisCard_Logo3.png"
              alt=""
            />
          </div>
          <div className="devicesHolder">
            <a
              href="https://play.google.com/store/apps/details?id=com.chugo.lexiscard"
              target="_blank"
              className=""
              onClick={() => this.handleLinkClick()}
            >
              <img className="badge" src="assets/images/google_play_transparent.png" alt="Play Store" />{" "}
            </a>

            <a
              href="https://apps.apple.com/sg/app/lexiscard/id1615346658"
              target="_blank"
              className=""
              onClick={() => this.handleLinkClick()}
            >
              <img className="badge" src="assets/images/app_store_transparent.png" alt="App Store" />{" "}
            </a>

          </div>
          <div className="soc-holder">
            <img
              className="soc"
              src="../../assets/images/soc.png"
              alt=""
            />
          </div>
          <div className="footer-menu">
            <ul>
              <li><a href="/" onClick={() => this.handleWhatsApp("https://wa.me/6582051987")}><PhoneIcon className="icon" /> +65 8205 1987</a></li>
              <li><a href="/"><LanguageIcon className="icon" /> www.lexiscard.asia</a></li>
              <li><a href="/ContactUs"><EmailIcon className="icon" /> contact@lexiscard.asia</a></li>
              {/* <li><a href={isVoucher ? "#" : "https://www.lexisjobs.asia/" }>Visit LexisJobs.Asia</a></li> */}
            </ul>
          </div>
          <div className="links">
            <a href={isVoucher ? "#" : "https://www.facebook.com/LexisCard-Asia-110454391714067"} className="hvr-grow" target="_bank">
              <img src="../../assets/images/fb.png" alt="" className="footerSocial" />
            </a>
            <a href={isVoucher ? "#" : "https://www.instagram.com/lexiscard.asia/"} className="hvr-grow" target="_bank">
              <img src="../../assets/images/ig.png" alt="" className="footerSocial" />{" "}
            </a>
            <a href={isVoucher ? "#" : "https://www.linkedin.com/showcase/lexiscard-asia"} className="hvr-grow" target="_bank">
              <img src="../../assets/images/linkedin.png" alt="" className="footerSocial" />{" "}
            </a>
            <a href={isVoucher ? "#" : "https://www.tiktok.com/@lexiscard.asia"} className="hvr-grow" target="_bank">
              <img src="../../assets/images/tiktok.png" alt="" className="footerSocial" />{" "}
            </a>
          </div>
          <div className="termsLinks">
            
            <div className="link">
              <a href={isVoucher ? "#" : "https://www.lexiscard.asia/" }>Visit LexisCard.Asia</a>
            </div>
            <div className="link">
              <a href={isVoucher ? "#" : "/TermsConditions"}>Terms & Conditions</a>
            </div>
            <div className="link">
              <a href={isVoucher ? "#" : "/PrivacyPolicy"}>Privacy Policy</a>
            </div>
          </div>
          <p className="lexiscard">© LexisCard 2022</p>
        </footer>
      </>
    );
  }
}
