import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";

import { Button } from "@mui/material";
import { QrScanner } from "@yudiel/react-qr-scanner";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    // border: "2px solid #000",
    // boxShadow: 24,
};

export default function QRScanModal({
    open,
    handleClose,
    setOpen,
    handleScan,
}) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={style}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <QrScanner
                    constraints={{ facingMode: "environment" }}
                    containerStyle={{ width: "100%" }}
                    // onResult={(res) => console.log(res)}
                    onDecode={(result) => handleScan(result)}
                    onError={(error) => console.log(error?.message)}
                    scanDelay={2000}
                />
                <Button
                    variant="text"
                    sx={{ marginBottom: "8px", color: "#fff" }}
                    onClick={() => setOpen(false)}
                >
                    CANCEL
                </Button>
            </Box>
        </Modal>
    );
}
