import React, { Component } from "react";
import "./SignUpHeader.scss";
import Globals, { Corp_web, ButtonGradient } from "../Globals";
import {Stepper, Typography, Step, StepLabel, IconButton} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import MenuIcon from "@mui/icons-material/Menu";
import MenuDrawer from "./MenuDrawer";

class SignUpHeader extends Component {
  listener = null;
  constructor(props) {
    super(props);
    this.state = {
      _menuDrawer: React.createRef(),
    }
  }
  componentDidMount() {
  }

  handleLogout = () => {
    this.setState(() => ({
      hasLogin: false,
      onLogin: false
    }),
    () => {
      Cookies.remove("auth");
      Cookies.remove("selectedPlan");
      this.props.setActivetab(0);
      this.props.setCorpLogin(false);
      window.location.reload();
    })
  }

  handleLogin = () => {
    if(!this.props.isCorpLogin){
      window.location.href = Corp_web;
    }
  }

  menuClick = () => {
    this.state._menuDrawer.current.openDrawerMethod();
  };

  openHome = () => {
    window.location.href = "/"
  }

  render() {
    const hasCookie = this.state.hasLogin;
    const isLogin = this.props.isCorpLogin;
    const steps = isLogin ? [
      {
        name: 'Select Plan',
        icon: ListAltIcon
      },
      {
        name: 'Payment',
        icon: PointOfSaleIcon
      }
    ] : [
      {
        name: 'Register',
        icon: AccountCircleIcon
      },
      {
        name: 'Select Plan',
        icon: ListAltIcon
      },
      {
        name: 'Payment',
        icon: PointOfSaleIcon
      }
    ]

    return (
      <div id="SignUpHeader">
        <nav className="container">
          <div className="containerBody">
            <div className="logo-holder">
              <>
                <div className="menu-icon">
                  <IconButton onClick={this.menuClick}>
                    <MenuIcon fontSize="large" menuClick={this.menuClick} handleLogin={this.handleLogin} />
                  </IconButton>
                </div>
                <MenuDrawer ref={this.state._menuDrawer} isActiveTab={isLogin} handleLogin={this.handleLogin} handleLogout={this.handleLogout} />
              </>
              <img
                className="logo"
                src={"../assets/images/LexisCard_Logo3.png"}
                alt="lexiscard logo"
                onClick={this.openHome}
              />
            </div>
            <div className="menu">
              <Stepper className="stepper" activeStep={this.props.activeTab}>
                {steps.map((item, index) => {
                  return (
                    <Step className="steps" key={item.name}>
                      <StepLabel className="stepsLabel" StepIconComponent={item.icon}>{item.name}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <div className="menu-text auth">
              {
                !isLogin ? <a href="javascript:void(0)" onClick={() => this.handleLogin()} className="login">Login</a> : <a href="javascript:void(0)" onClick={() => this.handleLogout()} className="login">Logout</a>
              }
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const customState = (state) => {
  return {
    isCorpLogin: state.isCorpLogin,
    activeTab: state.activeTab
  };
}

const customDispatch = dispatch => {
  return{
    setCorpLogin: (val) => dispatch({type: 'setCorpLogin', value: val}),
    setActivetab: (val) => dispatch({type: 'setActiveTab', value: val})
  }
}

export default connect(customState, customDispatch)(SignUpHeader)
