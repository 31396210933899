import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

export default function AlertDialog({
    open,
    handleClose,
    title,
    message,
    buttonCaption,
    containerStyles,
    titleStyles,
    contentStyles,
    buttonStyles,
}) {
    return (
        <Dialog
            PaperProps={{
                sx: {
                    bgcolor: containerStyles?.backgroundColor
                }
            }}
            fullWidth
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && (
                <DialogTitle sx={titleStyles} id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={[{ fontSize: "16px" }, contentStyles]}
                >
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus sx={buttonStyles}>
                    {buttonCaption}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
