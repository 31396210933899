import BasePageComponent from '../components/BasePageComponent';
import "./Voucher.scss";
import QRCode from "react-qr-code";
import Box from '@mui/material/Box';
import {Base64} from 'js-base64';

export default class Voucher extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            qrcode: '',
        }
    }

    componentDidMount(){
        let url = window.location.pathname;
        url = url.split("/");
        let QRCode = url[url.length -1];
        QRCode = Base64.decode(QRCode);
        QRCode = Base64.decode(QRCode);
        this.setState({
            qrcode: QRCode
        });
    }

    render() {
        const value = this.state.qrcode;
        return super.render(
            <div className='Voucher'>
                <img
                    className="logo"
                    src={"../assets/images/LexisCard_Logo.png"}
                    alt="lexiscard logo"
                />
                
                <div className='qrholder'>
                    <QRCode
                        size={200}
                        style={{ height: "auto", maxWidth: "100%", width: "100%", borderRadius: 10 }}
                        value={value}
                        viewBox={`0 0 256 256`}
                    />
                </div>
                <h2 className='qrtitle'>Voucher QR Code</h2>
            </div>
        )
    }
}