import BasePageComponent from "../components/BasePageComponent";
import "./Home.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ButtonGradient } from "../Globals";
import TextField from "@mui/material/TextField";
import Api, { ApiPost } from "../services/Api";
import ResponsiveCarousel from "../components/ResponsiveCarousel";
import { Box, Modal, IconButton } from "@mui/material";
import InnovativeFeatures from "../components/InnovativeFeatures";
import LifeStyle from "../components/LifeStyle";
import CloseIcon from '@mui/icons-material/Close';
import TagManager from 'react-gtm-module';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: 'auto',
  maxHeight: '90%',
  borderRadius: 4
};
const lexisEventStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '80%',
  width: '90%',
  borderRadius: 4
};

const tableData = [
  {
    label: '',
    lexisCard: '',
    competitors: ''
  },
  {
    label: 'Contacts Storage',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Customer Support',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Multi Language',
    lexisCard: true,
    competitors: false
  },
  {
    label: 'Business card scanner (unlimited use)',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Unlimited own QR code sharing',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Own namecard image sharing',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Own eCard sharing',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Remove in-app advertisements',
    lexisCard: true,
    competitors: false
  },
  {
    label: 'Social Media Links',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Auto back-up to own cloud',
    lexisCard: true,
    competitors: false
  },
  {
    label: 'HR Admin Dashboard',
    lexisCard: true,
    competitors: false
  },
  {
    label: 'Add contacts to groups',
    lexisCard: true,
    competitors: true
  },
  {
    label: 'Export connections to excel',
    lexisCard: true,
    competitors: false
  }
]

const clients = ['/assets/images/nuhs.png','/assets/images/jgc.png','/assets/images/challenger.jpg', '/assets/images/hermes.jpg']
// const logoIcons = ['/assets/images/logoIcon1.png','/assets/images/logoIcon2.png','/assets/images/logoIcon3.png','/assets/images/logoIcon4.png','/assets/images/logoIcon5.png']

const logoIcons = ['/assets/images/logoIcon1.png','/assets/images/logoIcon3.png','/assets/images/logoIcon4.png']
// const logoIcons = ['/assets/images/nuhs.png','/assets/images/jgc.png','/assets/images/challenger.jpg', '/assets/images/hermes.jpg']


const cards = ['Card 1', 'Card 2', 'Card 3', 'Card 4']; // Add your card content here

const corpCheckList = [
  "Analyze the usage of namecards, view and download the contacts of your employees that have been stored.",
  "Easily add, edit, and delete employee access.",
  "Manage admin access up to 3 levels (Super Admin/Dept Admin/Basic Admin)",
  "Auto-alignment of fields in the namecard",
  "Upload change of namecard template when needed",
  "Integrate with multiple platforms with our API"
]


const rows = [
  {annual: "ANNUAL (PER USER)",business:'$8.80',exterprise_plan:'$17'},
  {annual:"MONTHLY (PER USER)",business:'$5',exterprise_plan:'$10'},
  {annual:"CONTACTS STORAGE",business:'300',exterprise_plan:'500'}, 
  {annual:"NAMECARD TEMPLATES",business:()=> <p>3 <br/> (1x customized design) <br/> (2x Digitalisation of existing namecard)</p> ,exterprise_plan:()=> <p>5<br/> (2x customized design) <br/> (3x Digitalisation of existing namecard)</p>},
  {annual:()=> <p>LEXISEVENTS CREDITS <br/> (ONE TIME)</p>,business:'30',exterprise_plan:'50'},
  {annual:()=> <p>ADD ON <br/> (ONE TIME PAYMENT)</p>,business:()=><p>Customize namecard <br/> design $28</p>,exterprise_plan:()=><p>Customize namecard <br/> design $18</p>},
  {annual:()=> <p>ADD ON <br/> (ANNUAL ONLY)</p> ,business:()=><p>SGD$18 for every 50 additional <br/> contacts storage space</p>,exterprise_plan:()=><p>SGD$18 for every 50 additional <br/> contacts storage space</p>}
]


export default class Home extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phoneNumber: undefined,
      email: "",
      subject: "",
      message: "",
      errorName: "",
      errorEmail: "",
      errorPhoneNumber: "",
      errorSubject: "",
      errorMessage: "",
      captcha: "",
      random_captcha: "",
      form_id: null,
      qrCode: "https://onelink.to/jrmghz",
      requestEmail: '',
      modalOpen: false,
      index: 0,
      delayRender:false,
      lexisEventOpen: false,
      selectedOptionPlan: 'personal'
    };
  }

  componentDidMount() {
    TagManager.initialize({ gtmId: 'GTM-W3WG93Z' });
    this.getRecaptcha();
    setTimeout(()=>{
        this.setState({delayRender:true})
    },1500)
  }

  getRecaptcha = async () => {
    const res = await Api.get(`/customCaptcha`);
    const {random, uuid} = res.data.data;
    if (random !== "") {
      this.setState({
        random_captcha: random,
        form_id: uuid
      });
    }
  };

  contactUs = async () => {
    try {
      const res = await ApiPost(`/requestEmail`, {email: this.state.requestEmail});
      console.log("res", res)
      this.setState({requestEmail: ''});
    } catch (error) {
      console.log("error", error)
    }
  }

  downloadCalendar = async () => {
    window.open('https://lexicard-public.s3.ap-southeast-1.amazonaws.com/pushnotification/LexisCard+Calendar.pdf', '_blank')
  }

  handleClose = () => {
    this.setState({modalOpen: false});
  }

  onSwipe = (direction) => {
    console.log('You swiped: ' + direction)
  }
  
  onCardLeftScreen = (myIdentifier) => {
    console.log(myIdentifier + ' left the screen')
  }

  handleOptionSelect = (option) => {
    this.setState({ selectedOptionPlan: option });
  };

  clickDownloadApp = () => {
    document
      .getElementById("footer")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }

  render() {
    // const animate = this.state.animation;
    const animate = true;
    const modalOpen = this.state.modalOpen;
    const lexisEventOpen = this.state.lexisEventOpen;
    const selectedOptionPlan = this.state.selectedOptionPlan;
    const { index } = this.state;

    return super.render(
      <div className="Home">
        <div id="hero" className="bannerHolder">
          <div className="container" style={{paddingTop: animate ? "50px" : "60px"}}>
            <div className="leftBanner" style={animate ? {display: 'flex',marginLeft:'0px',zIndex:1,width:"60%",marginTop:'-70px',paddingTop:'140px',opacity:1} : {zIndex: 0,marginTop:'-70px',paddingTop:'140px',opacity:0,width:'30%', position:'absolute'}}>
              <div className="cardBanner">
                <div className="content">
                  <p className="cardbannerTitle">
                    Unlock the Power of Event Management with LexisEvents
                  </p>
                  <p className="cardBannerDesc">
                    Elevate your events effortlessly. Maximize attendance and engagement with our intuitive tools and robust features, tailored for all your event needs.
                  </p>
                  <div className="cardsHolder" style={{display:animate  && this.state.delayRender ? 'block' :"none"}}>
                    <ResponsiveCarousel/>
                  </div>
                </div>
                <div className="download">
                  <a className="downloadText" href="javascript:void(0)" onClick={this.clickDownloadApp}>Download App</a>
                  <p className="downloadPara">Start maximizing your event potential with LexisEvents today!</p>
                </div>
              </div>
            </div>
            <div className="mainBanner" style={!animate ? {maxWidth: 'unset', width: '100%', backgroundPosition: '130%'} : {paddingLeft:0}}>
              <div className="bannerContent" style={{paddingLeft:animate && "40px"}}>
                <h1 className="bannerTitle">Revolutionize<br /> Your Networking</h1>
                <p className="bannerDescription">Networking Simplified, Opportunities Amplified</p>
                <div className="bannerBtnHolder">
                  {/* <button onClick={() => {
                    // this.setState({modalOpen: true})
                    window.location.href = "./Corp/SignUp";
                  }} className="btnPlan">Select Plan</button> */}
                  <img
                    className="logo2"
                    src={"assets/images/lexisEvent.png"}
                    alt="lexiscard logo"
                    onClick={() => { this.setState({lexisEventOpen: true}) }}
                  />
                </div>
                <div className="featureHolder">
                  <p className="label">Featured In</p>
                  <div className="iconsHolder">
                    {
                      logoIcons.map((data, inx) => {
                        return (
                          <div className="logoIconHolder">
                            <img src={data} />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="imageHolder" style={{width:animate ?'100%':"60%",height:animate ? '60%':'100%', bottom:animate ? "12px" :"-10px",right:"-110px"}}>
                <img src="/assets/images/banner_background1.png" style={{height:"95%",width:"100%"}}/>
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <img className="chugo_logo" src="/assets/images/chugo_logo.png"/>
            <p className="paragraph">CHuGO Pte Ltd is a dynamic and innovative software development company that’s the forefront of digital innovation and customer engagement. Headquartered in Singapore, we are dedicated to shaping the future of networking through technology. Our mission is to empower businesses & individuals to navigate the fast-paced corporate environment with efficiency, scalability, and impact with LexisSuite.</p>
            <img className="lexis_group" src="/assets/images/lexis_group.png"/>
            <p className="paragraph">LexisSuite, a pioneering digital solution designed to redefine how businesses engage with professional networking, event management, and customer loyalty, by unifying LexisCard, LexisEvents, LexisPoints, and LexisVendor into a single ecosystem. This integration facilitates seamless networking, event organization, and loyalty program management, all from one comprehensive platform.</p>
          </div>
        </div>
        <div id="event" className="eventSection">
          <div className="container">
            <div className="imgHolder">
              <img src="/assets/images/event1.png" />
              <img src="/assets/images/event2.png" />
              <img src="/assets/images/event3.png" />
            </div>
            <ButtonGradient
              className="pdfBtn"
              variant="contained"
              color="secondary"
              onClick={this.downloadCalendar}
            >
              Download Calendar
            </ButtonGradient>
          </div>
        </div>
        <div className="section3">
          <div className="container">
            <div className="corporate_customers_container">
              <p className="corporate_customers">Our Corporate Customers</p>
              <div className="contentDivider"></div>
              <p className="paragraph">It's this commitment to excellence that has forged lasting relationships with our clients, who have become more than just business partners; they're an integral part of our journey. As we look back on the years of collaboration, we're grateful for their trust and proud of the work we've accomplished together.</p>
            </div>
            <img className="corporate_customers_image" src="/assets/images/corporate_customers.png"/>
          </div>
        </div>
        <div className="section4">
          <div className="container">
            <p className="environmental_impact">The Environmental Impact of Digital Business Cards: A Sustainable Shift</p>
            <div className="service_grid_container">
              <div className="service_grid">
                <img className="service_image" src="/assets/images/paper_savings.png"/>
                <p className="service_title">Paper Savings</p>
                <p className="service_paragraph">A standard business card weighs about 0.1 ounces. If a professional uses 500 business cards annually, that's 50 ounces or 3.125 lbs of paper. For 1 million professionals switching to digital, it would save approximately 3,125,000 lbs (or 1,562.5 tons) of paper globally.</p>
              </div>
              <div className="service_grid">
                <img className="service_image" src="/assets/images/save_the_tree.png"/>
                <p className="service_title">Save The Trees</p>
                <p className="service_paragraph">Using the estimate that 17 trees produce 1 ton of paper, this equates to about 26,562 trees saved for every 1 million professionals switching to digital cards (1,562.5 tons * 17 trees/ton).</p>
              </div>
              <div className="service_grid">
                <img className="service_image" src="/assets/images/carbon_footprint.png"/>
                <p className="service_title">Your Carbon Footprint</p>
                <p className="service_paragraph">If the production of 2,000 lbs of paper emits approximately 3,300 lbs of CO2, then saving 3,125,000 lbs of paper would result in a reduction of approximately 5,156,250 Ibs (or 2,578 tons) of CO2 emissions globally.</p>
              </div>
            </div>
          </div>
        </div>
        <div id="howWorks" className="section5">
          <div className="container">
            <p className="section5_title">LexisCard Infused with Innovative Features</p>
            <InnovativeFeatures
              data={{
                title: 'Your data is secure',
                paragraph: 'Intelligent AI scanner to parse namecard details and store all data securely.',
                icon_image: '/assets/images/feature1.png',
                image: '/assets/images/feature1_img.png',
                isLeft: true
              }}
            />
            <InnovativeFeatures
              data={{
                title: 'Efficiently Designed',
                paragraph: 'Customize your digital profile, update anytime & share instantly via Whatsapp, Telegram, email, sms & more.',
                icon_image: '/assets/images/feature2.png',
                image: '/assets/images/feature2_img.png',
                isLeft: false,
                upper_img: '/assets/images/feature2_img_upper.png'
              }}
            />
            <InnovativeFeatures
              data={{
                title: 'Interactive Contact Book',
                paragraph: 'Scan & save your customer’s namecard, connect instantly without leaving the app',
                icon_image: '/assets/images/feature3.png',
                image: '/assets/images/feature3_img.jpg',
                isLeft: true,
                upper_img: '/assets/images/feature2_img_upper.png'
              }}
            />
            <InnovativeFeatures
              data={{
                title: 'Organize your contacts by Groups',
                paragraph: 'Group your contacts by unique identifiers',
                icon_image: '/assets/images/feature4.png',
                image: '/assets/images/feature4_img.png',
                isLeft: false
              }}
            />
            <InnovativeFeatures
              data={{
                title: 'Events Management',
                paragraph: 'Curate a personalised event & share with your customers instantly. RSVP management at your fingertips.',
                icon_image: '/assets/images/feature5.png',
                image: '/assets/images/feature5_img.png',
                isLeft: true
              }}
            />
            <InnovativeFeatures
              data={{
                title: 'Increase customer Loyalty with LexisPoints',
                paragraph: 'Engage your customers. Designed to reward active users of LexisSuite, offering tangible benefits for engagement.',
                icon_image: '/assets/images/feature6.png',
                image: '/assets/images/feature6_img.png',
                isLeft: false
              }}
            />
            <InnovativeFeatures
              data={{
                title: 'Be a LexisVendor',
                paragraph: 'Be part of the LexisCard network and sell your products & services to all personal & corporate subscribers.',
                icon_image: '/assets/images/feature7.png',
                image: '/assets/images/feature7_img.png',
                isLeft: true
              }}
            />
            <InnovativeFeatures
              data={{
                title: 'List your products',
                paragraph: 'List your products & services to be redeemed or purchased by all users.',
                icon_image: '/assets/images/feature8.png',
                image: '/assets/images/feature8_img.png',
                isLeft: false
              }}
            />
          </div>
        </div>
        <div className="section6">
          <div className="column1">
            <div className="column1_text_container">
              <img className="logo" src="/assets/images/LexisCard_Logo4.png"/>
              <p className="title">There’s no digital namecard app like LexisCard</p>
              <div className="contentDivider"></div>
              <p className="subtitle">Never let a connection slip away again</p>
              <p className="paragraph">In a world where first impressions and connections matter, why leave it to paper that gets lost or outdated?<br/><br/>
              Discover LexisCard – the digital business card that ensures your contacts always have your latest information at their fingertips. Redefine business introductions with customisable, eco-friendly digital business cards, facilitating meaningful connections in the digital age.<br/><br/>
              Say goodbye to missed opportunities and hello to seamless networking.</p>
            </div>
            <img className="column1_image" src="/assets/images/lexiscard_support.png"/>
          </div>
          <div className="column2">
            <img className="column2_image" src="/assets/images/lexiscard_support2.png"/>
            <div className="column2_text_container">
              <img className="logo" src="/assets/images/LexisEvent_2.png"/>
              <p className="title">Elevate Your Events and Engage Your Audience Like Never Before with LexisEvents!</p>
              <div className="contentDivider"></div>
              <p className="paragraph">With LexisEvents, service providers can leverage a range of features to ensure the success of their events.</p>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Easy event setup with customisable templates for various types of events.</p>
              </div>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Integrated ticketing and registration systems to manage attendees smoothly.</p>
              </div>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Promotion tools to reach the right audience within the LexisSuite community and beyond.</p>
              </div>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Real-time analytics to track event success and attendee engagement.</p>
              </div>
              <div className="list_container" style={{marginBottom: 16}}>
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Networking features that allow participants to connect and engage before, during, and after the event.</p>
              </div>
              <p className="paragraph">For service providers, LexisEvents is not just about event management; it's a platform to showcase expertise, build brand awareness, and create meaningful connections within the professional community.</p>
            </div>
            <div className="column2_rectangle"></div>
          </div>
          <div className="column3">
            <div className="column3_text_container">
              <img className="logo" src="/assets/images/lexisPoints.png"/>
              <p className="title">Boost your business with every interaction</p>
              <div className="contentDivider"></div>
              <p className="paragraph">LexisPoints revolutionizes the way service providers connect with their audience. By becoming part of the LexisPoints ecosystem, service providers can offer exclusive rewards, discounts, and special offers directly within the LexisSuite network. This integration not only drives customer retention but also encourages repeated engagements, as customers are motivated to earn points through various interactions and transactions. For service providers, LexisPoints is a tool to:</p>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Enhance visibility within a targeted professional community.</p>
              </div>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Drive engagement through a rewards system that incentivizes repeat business.</p>
              </div>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Collect valuable data on customer preferences and behavior for tailored offerings.</p>
              </div>
              <div className="list_container">
                <img className="check_logo" src="/assets/images/check.png"/>
                <p className="paragraph">Strengthen customer relationships by providing value beyond the transaction.</p>
              </div>
            </div>
            <img className="column3_image" src="/assets/images/lexiscard_support2.png"/>
            <div className="column3_rectangle"></div>
          </div>
          <div className="column4">
            <img className="column4_image2" src="/assets/images/lexiscard_support.png"/>
            <div className="column4_white_container"></div>
            <div className="column4_text_container">
              <div className="container">
                <img className="logo" src="/assets/images/lexisVendor.png"/>
                <p className="title">Boost your business with every interaction</p>
                <div className="contentDivider"></div>
                <p className="paragraph">LexisPoints revolutionizes the way service providers connect with their audience. By becoming part of the LexisPoints ecosystem, service providers can offer exclusive rewards, discounts, and special offers directly within the LexisSuite network. This integration not only drives customer retention but also encourages repeated engagements, as customers are motivated to earn points through various interactions and transactions. For service providers, LexisPoints is a tool to:</p>
                <div className="list_container">
                  <img className="check_logo" src="/assets/images/check_white.png"/>
                  <p className="paragraph">List their services and products on a platform tailored for the professional market.</p>
                </div>
                <div className="list_container">
                  <img className="check_logo" src="/assets/images/check_white.png"/>
                  <p className="paragraph">Offer exclusive discounts and deals to the LexisSuite community, enhancing brand loyalty.</p>
                </div>
                <div className="list_container">
                  <img className="check_logo" src="/assets/images/check_white.png"/>
                  <p className="paragraph">Gain direct access to a targeted audience of professionals actively seeking quality services.</p>
                </div>
                <div className="list_container">
                  <img className="check_logo" src="/assets/images/check_white.png"/>
                  <p className="paragraph">Receive feedback and insights from a professional community to improve offerings.</p>
                </div>
                <div className="list_container">
                  <img className="check_logo" src="/assets/images/check_white.png"/>
                  <p className="paragraph">Increase brand visibility and awareness through targeted promotions and partnerships within the LexisSuite ecosystem.</p>
                </div>
              </div>
            </div>
            <img className="column4_image" src="/assets/images/lexiscard_support.png"/>
          </div>
        </div>
        <div id="partnerUS">
          <LifeStyle/>
        </div>
        <div className="customer_review_container">
           <div className="left_side">
            
              <div className="imageHolder">
            
            
                  <div className="img_1">
                      <img src="/assets/images/customer1.png"/>
                  </div>
                  
                  <div className="img_2">
                       <img src="/assets/images/customer_2.png"/>
                  </div>
              </div>
           </div>
           <div className="right_side">
                <p className="headline">Our customers <br/>reviews</p>
                <div className="content">
                  <img src="/assets/images/quoteICON.png"/>
                  <p className="description">“By the way, I love the app and looking forward to to having more perks like more customizable logos and card!”</p>
                  <p className="name">Mario L.</p>
                  <p className="position">Founder CEO, Company</p>
                </div>
           </div>
        </div>
        <div id="pricing" className="corporate_dashboard_container">
            <div className="overflow_gray"></div>
            <div className="secondary_container">
            <div className="section_1">
                  <div className="left_side">
                      <p className="label">Corporate Dashboard</p>
                      <p className="description">LexisPoints revolutionizes the way service providers connect with their audience. By becoming part of the LexisPoints ecosystem, service providers can offer exclusive rewards, discounts, and special offers directly within the LexisSuite network. This integration not only drives customer retention but also encourages repeated engagements, as customers are motivated to earn points through various interactions and transactions. For service providers, LexisPoints is a tool to:</p>
                  </div>
                  <div className="rigth_side">
                      <img src="/assets/images/dashboard1.png"/>
                  </div>
            </div>
            <div id="pricingMain" className="section_2">
              {/* <table>
                  <thead>
                      <tr>
                          <th><p>CORPORATE <br/> SUBSCRIPTIONS </p></th>
                          <th><p>BUSINESS PLAN</p></th>
                          <th><p>ENTERPRISE PLAN</p></th>
                      </tr>
                  </thead>
                  <tbody>
                    {rows.map((data)=>{
                       return  <tr>
                       <td>{typeof data.annual === 'function' ? data.annual() : data.annual}</td>
                       <td>{typeof data.business === 'function' ? data.business() : data.business}</td>
                       <td>{typeof data.exterprise_plan === 'function' ? data.exterprise_plan() : data.exterprise_plan}</td>
                   </tr>
                    })}
                  </tbody>
              </table> */}
            </div>
            <div className="section_3">
                 <div className="left_side">
                      <p className="label">Corporate Dashboard</p>
                      <p className="description">
                      {corpCheckList.map((desc)=>{
                         return  <div className="desc_items">
                             <CheckCircleIcon style={{marginRight:'10px',color:'gray'}}/>
                         <div className="desc">
                            {desc}
                         </div>
                     </div>
                       })}
                             
                      </p>
                  </div>
                  <div className="rigth_side">
                      <img src="/assets/images/dashboard1.png"/>
                  </div>
            </div>
            </div>
        </div>
        <div id="lexisSuite" className="lexis_suite">
          <div className="container">
            <div className="lexis_suite_title">
              <p className="title">Why</p>
              <img className="title_logo" src="/assets/images/lexisSuite_Dark.png"/>
              <p className="title">?</p>
            </div>
            <div className="lexis_suite_content">
              <div className="lexis_suite_feature_main_container">
                <div className="feature_container">
                  <img className="check_image" src="assets/images/check_green.png"/>
                  <div className="feature_details">
                    <p className="title">Integrated Platform</p>
                    <div className="contentDivider"></div>
                  </div>
                </div>
                <div className="feature_container">
                  <img className="check_image" src="assets/images/check_green.png"/>
                  <div className="feature_details">
                    <p className="title">Innovative Networking Solutions</p>
                    <div className="contentDivider"></div>
                  </div>
                </div>
                <div className="feature_container">
                  <img className="check_image" src="assets/images/check_green.png"/>
                  <div className="feature_details">
                    <p className="title">Streamlined Event Management</p>
                    <div className="contentDivider"></div>
                  </div>
                </div>
              </div>
              <img className="lexis_suite_content_image" src="/assets/images/lexis_suite.png"/>
              <div className="lexis_suite_feature_main_container">
                <div className="feature_container">
                  <img className="check_image" src="assets/images/check_green.png"/>
                  <div className="feature_details">
                    <p className="title">Rewarding Loyalty Program</p>
                    <div className="contentDivider"></div>
                  </div>
                </div>
                <div className="feature_container">
                  <img className="check_image" src="assets/images/check_green.png"/>
                  <div className="feature_details">
                    <p className="title">Targeted Business Opportunities</p>
                    <div className="contentDivider"></div>
                  </div>
                </div>
                <div className="feature_container">
                  <img className="check_image" src="assets/images/check_green.png"/>
                  <div className="feature_details">
                    <p className="title">Corporate Empowerment</p>
                    <div className="contentDivider"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={modalOpen}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div id="planModal">
              <div className="head">
                <p className="userPlan">Select User Plan</p>
                <p className="planDescription">You can change your plan at any time</p>
              </div>
              <div className="body">
                <div className={`card ${selectedOptionPlan === 'personal' ? 'selected' : ''}`} onClick={() => this.handleOptionSelect('personal')}>
                  <img src='/assets/images/personIcon.png' />
                  <label className="cardLabel">Personal</label>
                  <p className="cardDesc">Discover unique and personalized namecard designs that reflect your individuality. Elevate your networking game with our bespoke creations that leave a lasting impression.</p>
                </div>
                <div className={`card ${selectedOptionPlan === 'corporate' ? 'selected' : ''}`} onClick={() => this.handleOptionSelect('corporate')}>
                  <img src='/assets/images/corporateIcon.png' />
                  <label className="cardLabel">Corporate</label>
                  <p className="cardDesc">Unlock the power of professional branding with our customizable namecard solutions. Elevate your corporate identity and networking experiences with our premium designs tailored for businesses.</p>
                </div>
              </div>
              <div className="footer">
                <button class="btnPlan">Credits</button>
                <button class="btnPlanCancel" onClick={this.handleClose}>Cancel</button>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={lexisEventOpen}
          onClose={() => { this.setState({lexisEventOpen: false}) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={lexisEventStyle}>
            <IconButton
              aria-label="close"
              onClick={() => { this.setState({lexisEventOpen: false}) }}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                justifyContent: 'right',
                color: '#FFFFFF',
                boxShadow: 'none',
              }}
            >
              <CloseIcon sx={{ fontSize: 32 }} />
            </IconButton>
            <div id="lexisEventModal">
              <div className="leftBanner">
                <div className="cardBanner">
                  <div className="content">
                    <p className="cardbannerTitle">
                      Unlock the Power of Event Management with LexisEvents
                    </p>
                    <p className="cardBannerDesc">
                      Elevate your events effortlessly. Maximize attendance and engagement with our intuitive tools and robust features, tailored for all your event needs.
                    </p>
                    <div className="cardsHolder">
                      <ResponsiveCarousel/>
                    </div>
                  </div>
                  <div className="download">
                    <a className="downloadText" href="javascript:void(0)" onClick={() => {
                      this.setState({lexisEventOpen: false});
                      this.clickDownloadApp()
                    }}>Download App</a>
                    <p className="downloadPara">Start maximizing your event potential with LexisEvents today!</p>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}
