import { AccessTime, Email, EventNote,Person, Rowing } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Card, CardContent, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

export default function AttendanceCard({ row, isDownMd }) {
    return (
        <Card
            elevation={3}
            sx={{
                bgcolor: "#111621",
                mb: '24px',
                color: "#fff",
            }}
        >
            <CardContent
                sx={(theme) => ({
                    p: 4,
                    [theme.breakpoints.up("md")]: {
                        px: 8,
                    },
                })}
            >
                {
                    row?.isCorp ? 
                        row?.metadata[0]?.front[0]?.name ?
                            <Box
                                display={"flex"}
                                flex={1}
                                flexDirection={"row"}
                                alignItems={"center"}
                                marginBottom={1}
                            >
                                <Person fontSize="small" sx={{ mr: 1, color: "#FF5666" }} />
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                    }}
                                    component="div"
                                >
                                    {row?.metadata[0]?.front[0]?.name}
                                </Typography>
                            </Box>
                            :null
                    :
                        row?.meta?.name ?
                            <Box
                                display={"flex"}
                                flex={1}
                                flexDirection={"row"}
                                alignItems={"center"}
                                marginBottom={1}
                            >
                                <Person fontSize="small" sx={{ mr: 1, color: "#FF5666" }} />
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                    }}
                                    component="div"
                                >
                                    {row?.meta?.name}
                                </Typography>
                            </Box>
                        : null
                }
                <Box
                    display={"flex"}
                    flex={1}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <Email fontSize="small" sx={{ mr: 1, color: "#FF5666" }} />
                    <Typography
                        sx={{
                            fontSize: "12px",
                        }}
                        component="div"
                    >
                        {row?.useremail}
                    </Typography>
                </Box>

                <Box
                    sx={{ mt: '12px' }}
                    display={"flex"}
                    flex={1}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <EventNote
                        fontSize="small"
                        sx={{ mr: 1, color: "#FF5666" }}
                    />
                    <Typography
                        sx={{
                            fontSize: "12px",
                            color: '#8F9EB6'
                        }}
                    >
                        {moment(row.created_on).format("MMMM DD, YYYY | hh:mm A")}
                    </Typography>
                </Box>

                <Box
                    sx={{ mt: '12px' }}
                    display={"flex"}
                    flex={1}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <Box
                        sx={{
                            heigth: "20px",
                            width: "20px",
                            mr: 1,
                        }}
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Box
                            sx={{
                                height: "12px",
                                width: "12px",
                                borderRadius: "50%",
                                bgcolor: "#53D732",
                            }}
                        />
                    </Box>

                    <Typography 
                        sx={{
                            fontSize: "12px",
                            color: '#8F9EB6'
                        }}>
                        Present
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}
