import React, { Component} from 'react';
import './TryLexisCard.scss';

export default class TryLexisCard extends Component  { 
    constructor(props){
        super(props)
        this.state = {
        }
    }
    async componentDidMount () {

    }

    render(){
        return (
            <div className="TryLexisCard">
                <div className="card">
                <p>
                  Try{" "}
                  <img
                    className="logo"
                    src="assets/images/LexisCard_Logo.png"
                    alt="lexiscard logo"
                  />{" "}
                  for free today!
                </p>
                <p className="stores">
                  <a
                    href="https://apps.apple.com/sg/app/lexiscard/id1615346658"
                    target="_blank"
                    className=""
                  >
                    <img className="badge" src="assets/images/appStore.png" alt="App Store" />{" "}
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.chugo.lexiscard"
                    target="_blank"
                    className=""
                  >
                    <img className="badge" src="assets/images/playStore.png" alt="Play Store" />{" "}
                  </a>

                  {/* <a href="#2" className="">
                    <img
                      className="badge"
                      src="assets/images/appGallery.png"
                      alt="App Gallery"
                    />
                  </a> */}
                </p>
              </div>
            </div>
        )
    }
}