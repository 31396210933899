import logo from './logo.svg';
import './App.css';
import Routes from './Routes'
import {
  BrowserRouter as Router
} from "react-router-dom";
import { GlobalContext } from './Globals'
import React, { Component} from 'react';
import { Provider } from 'react-redux';
import Store from './redux/Store';

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appConst: []
    }
  }

  render() {
    return (
      <>
      <Provider store={Store}>
        <GlobalContext.Provider value={this.state.appConst}>
          <Router>
            <Routes />
          </Router>
        </GlobalContext.Provider>
      </Provider>
      </>
    );
  }

}

