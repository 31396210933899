import BasePageComponent from '../components/BasePageComponent';
import "./Result.scss";
import {Base64} from 'js-base64';
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import {Corp_web} from '../Globals';
import { ApiPost } from '../services/Api';


class Result extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            status: false,
            selected_plan: 'CPro',
            failed_message: null
        }
    }

    componentDidMount(){
        let path = window.location.pathname;
        let failed_msg;
        const status = path.includes("/Success");
        if(status){
            this.handlePullInvoice()
        }else{
            const splitURL = path.split('/');
            const code = splitURL[splitURL.length - 1]; 
            console.log("code", code)
            if(code !== "Failed"){
                failed_msg = JSON.parse(Base64.decode(code));
            }
        }

        console.log("failed message", failed_msg);
        this.setState({
            status: status,
            failed_message: failed_msg ? failed_msg.message : ""
        })
    }

    handlePullInvoice = async () => {
        let cookie = Cookies.get('auth');
        if(cookie){
            cookie = JSON.parse(cookie);
            const {email, corp_id} = cookie;
            try {
                const resp = await ApiPost("/corp/getInvoice", {corp_id: corp_id, email: email});
                if(resp.data.message === "success"){
                    const metadata = resp.data.data.metadata;
                    console.log("metadata", metadata);
                    this.setState({
                        selected_plan: metadata.selectedPlan
                    })
                }
                this.logout();
            } catch (error) {
                console.log("resp", error)
            }
        }
        
        

    }

    handleRetry = () => {
        window.location.href = "/Corp/SignUp";
    }
    
    handleGetStarted = () => {
        window.location.href = Corp_web;
        //this.logout();
    }

    logout = () => {
        Cookies.remove("auth");
        Cookies.remove("selectedPlan");
        this.props.setToDefault();
    }

    render() {
        const status = this.state.status;
        const selectedPlan = this.state.selected_plan;
        const failed_message = this.state.failed_message;
        const title = status ? "Welcome onboard!" : failed_message ? failed_message : "Something went wrong...";
        
        
        return super.render(
            <div id='Result'>
                <Card className='card' sx={{ minWidth: 275, maxWidth: 400 }}>
                    <Box component="div" className="icon-holder">
                        <div className="icon-round" style={status ? {backgroundColor: '#01082E'} : {backgroundColor: '#E5383B'}}>
                            {
                                status ? <CheckIcon className='icon' fontSize="large" /> : <CloseIcon className='icon' fontSize="large"/>
                            }
                        </div>
                    </Box>
                    <CardContent className='content'>
                        <Typography className='title' variant="h5" component="div">
                            {title}
                        </Typography>
                        {
                            status ? (
                                <Typography className='sub-details' style={{whiteSpace: 'pre-line'}}>
                                    You have successfully registered for LexisCard {selectedPlan} plan.<br/><br/> Please check your email for your invoice.
                                </Typography>
                            ) : 
                            failed_message ?
                            (
                                <Typography className='sub-details' style={{whiteSpace: 'pre-line'}}>
                                   Please try again.
                                </Typography>
                            ) : (
                                <Typography className='sub-details' style={{whiteSpace: 'pre-line'}}>
                                    We couldn't process your transaction. Please try again or <b>contact support</b>.
                                </Typography>
                            )
                        }
                        
                    </CardContent>
                    <CardActions className='btnHolder'>
                        {
                            status ? <Button fullWidth size="large" className="successBtn" onClick={() => this.handleGetStarted()}>Get Started</Button> : <Button variant="outlined" fullWidth size="large" className="failedBtn" onClick={() => this.handleRetry()}>Retry</Button>
                        }
                    </CardActions>
                </Card>
            </div>
        )
    }
}

const customState = state => {
    return {
        ProcessStatus: state.billStatus
    }
}
const customDispatch = dispatch => {
    return {
        setToDefault: () => dispatch({type: 'setToDefault'}),
    }
}

export default connect(customState, customDispatch)(Result)