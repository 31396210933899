const initialState = {
  isCorpLogin: false,
  activeTab: 0,
  billStatus: false,
  verify: false,
  verStatus: false,
  auditor: null,
}

const counterReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'setAuditor':
        return { ...state, auditor: action.value }
      case 'setCorpLogin':
        return { ...state, isCorpLogin: action.value}
      case 'setActiveTab':
          return { ...state, activeTab: action.value}
      case 'setBillStatus':
          return { ...state, billStatus: action.value}
      case 'setVerify':
        return { ...state, verify: action.value}
      case 'setVerStatus':
        return { ...state, verStatus: action.value}
      case 'setToDefault':
        return {
          isCorpLogin: false,
          activeTab: 0,
          billStatus: false,
          verify: false,
          auditor: null,
        }
      default:
        return state
    }
  }

export default counterReducer;