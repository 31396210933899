import { Email, EventNote } from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

export default function UiCard({ children }) {
    return (
        <Card
            elevation={3}
            sx={{
                bgcolor: "#111621",
                mb: "24px",
                color: "#fff",
                width: "100%",
            }}
        >
            <CardContent
                sx={{
                    padding: "16px",
                    "&:last-child": {
                        pb: '16px'
                    },
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
}
