import BasePageComponent from '../components/BasePageComponent';
import "./ContactUs.scss";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { ButtonGradient } from "../Globals";
import Button from "@mui/material/Button";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import FormControl from "@mui/material/FormControl";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import TextField from "@mui/material/TextField";
import Api from "../services/Api";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Cookies from "js-cookie";
import QRCode from "react-qr-code";
import TryLexisCard from "../components/TryLexisCard";


export default class ContactUs extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            phoneNumber: undefined,
            email: "",
            subject: "",
            message: "",
            errorName: "",
            errorEmail: "",
            errorPhoneNumber: "",
            errorSubject: "",
            errorMessage: "",
            captcha: "",
            errorCaptcha: "",
            random_captcha: "",
            form_id: null,
            alertMessage: ""
        }
    }

    componentDidMount() {
      this.getRecaptcha();
    }
    
    getRecaptcha = async () => {
      const res = await Api.get(`/customCaptcha`);
      const {random, uuid} = res.data.data;
      if (random !== "") {
        this.setState({
          random_captcha: random,
          form_id: uuid
        });
      }
    };

    handleWhatsApp = (val) => {
      window.open(val, "_blank")
    }

    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    handlePhoneNumberChange = (value) => {
      this.setState({ phoneNumber: value }, () => this.phoneNumberValidation());
    };

    nameValidation() {
      if (!this.state.name) {
        this.setState({ errorName: "Please enter a name." });
        return false;
      }
      this.setState({ errorName: "" });
      return true;
    }
  
    phoneNumberValidation() {
      if (!this.state.phoneNumber) {
        this.setState({ errorPhoneNumber: "Phone number required." });
        return false;
      }
  
      if (!isPossiblePhoneNumber(this.state.phoneNumber)) {
        this.setState({ errorPhoneNumber: "Invalid phone number." });
        return false;
      }
      this.setState({ errorPhoneNumber: "" });
      return true;
    }

    emailValidation() {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!this.state.email) {
        this.setState({
          errorEmail: "Please enter an email address.",
        });
        return false;
      } else if (regex.test(this.state.email) === false) {
        this.setState({
          errorEmail: "Email address is not valid.",
        });
        return false;
      }
      this.setState({
        errorEmail: "",
      });
      return true;
    }
  
    subjectValidation() {
      if (this.state.subject === "") {
        this.setState({ errorSubject: "Please enter a subject." });
        return false;
      }
      this.setState({ errorSubject: "" });
      return true;
    }
  
    messageValidation() {
      if (this.state.message === "") {
        this.setState({ errorMessage: "Please enter a message." });
        return false;
      }
      this.setState({ errorMessage: "" });
      return true;
    }

    sendMessage = async () => {
      this.nameValidation();
      this.phoneNumberValidation();
      this.emailValidation();
      this.subjectValidation();
      this.messageValidation();
  
      if (
        !this.nameValidation() ||
        !this.phoneNumberValidation() ||
        !this.emailValidation() ||
        !this.subjectValidation() ||
        !this.messageValidation()
      ) {
        return;
      }
  
      const data = {
        name: this.state.name,
        phoneNumber: formatPhoneNumberIntl(this.state.phoneNumber),
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
        captcha: this.state.captcha,
        uuid: this.state.form_id
      };
  
      const res = await Api.post(`/contactUsEmail`, data);
      if (res.data.data.status === 1) {
        this.getRecaptcha();
        this.setState({
          name: "",
          phoneNumber: undefined,
          email: "",
          subject: "",
          message: "",
          captcha: "",
          success: true,
          error: false,
          alertMessage: "Message Sent Successful!",
        });
      } else if (res.data.data.status === 2) { // expired captcha
        this.setState({
          error: true,
          success: false,
          alertMessage: "Expired captcha. Please regenerate new captcha.",
        });
      } else {
        this.setState({
          error: true,
          success: false,
          alertMessage: "Message already send. Please try again later!",
        });
      }
    };

    handleEmailClick = () => {
      const emailAddress = 'contact@lexiscard.asia';
      const mailtoLink = `mailto:${emailAddress}`;

      window.location.href = mailtoLink;
    };

    render() {
        const random_captcha = this.state.random_captcha;

        return super.render(
            <div id='ContactUs'>
                <div className="container">
                    <h1>Contact Us</h1>
                    <p className="sub">
                        Feel free to contact us to discuss about how our services can meet your needs. We
                        will get back to you as soon as possible.
                    </p>
                    <div className="flex">
                        <div className="left">
                            <div className="contactRow">
                                <PhoneIcon className="icon" />
                                <div className="text">
                                <p className="title">Call Us </p>
                                <div onClick={() => this.handleWhatsApp("https://wa.me/6582051987")} className="whatsup"><WhatsAppIcon color="green"/> <p className="content"> +65 8205 1987</p></div>
                                </div>
                            </div>
                            <div className="contactRow">
                                <EmailIcon className="icon" />
                                <div className="text">
                                <p className="title">Email Us</p>
                                <div onClick={() => this.handleEmailClick()} style={{cursor: 'pointer'}}><p className="content">contact@lexiscard.asia</p></div>
                                </div>
                            </div>
                            <div className="contactRow">
                                <LocationOnOutlinedIcon className="icon" />
                                <div className="text">
                                <p className="title">Address</p>
                                <p className="content">1 Ubi Link, Singapore 408553</p>
                                </div>
                            </div>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1185.859530552469!2d103.89235044662156!3d1.332003631840253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x661cc51cc119e567!2sChallenger%20Technologies%20Ltd!5e0!3m2!1sen!2ssg!4v1649749064559!5m2!1sen!2ssg"
                                width="100%"
                                height="280"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                        <div className="right">
                            <div className="form">
                                <div className="form-control">
                                <label htmlFor="name">Name</label>
                                <FormControl className="form-control" variant="outlined">
                                    <TextField
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="input"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                        "& >fieldset": {
                                            borderColor: "#c0c0c0",
                                        },
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                        "& >fieldset": {
                                            borderColor: "#1a67d2",
                                        },
                                        },
                                    }}
                                    fullWidth
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    />
                                    <div className="errorMessage">{this.state.errorName} </div>
                                </FormControl>
                                </div>
                                <div className="form-control">
                                <label htmlFor="phoneNumber">Contact Number</label>
                                <PhoneInput
                                    international
                                    className="input"
                                    placeholder="Enter phone number"
                                    countries={["SG", "MY", "BN", "KH", "ID", "LA", "MM", "PH", "TH", "VN"]}
                                    defaultCountry="SG"
                                    value={this.state.phoneNumber}
                                    onChange={this.handlePhoneNumberChange}
                                />
                                <div className="errorMessage">{this.state.errorPhoneNumber}</div>
                                </div>
                                <div className="form-control">
                                <label htmlFor="email">Email</label>
                                <FormControl className="form-control" variant="outlined">
                                    <TextField
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="input"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                        "& >fieldset": {
                                            borderColor: "#c0c0c0",
                                        },
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                        "& >fieldset": {
                                            borderColor: "#1a67d2",
                                        },
                                        },
                                    }}
                                    fullWidth
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    />
                                    <div className="errorMessage">{this.state.errorEmail} </div>
                                </FormControl>
                                </div>
                                <div className="form-control">
                                <label htmlFor="subject">Subject</label>
                                <FormControl className="form-control" variant="outlined">
                                    <TextField
                                    id="subject"
                                    name="subject"
                                    type="text"
                                    className="input"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                        "& >fieldset": {
                                            borderColor: "#c0c0c0",
                                        },
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                        "& >fieldset": {
                                            borderColor: "#1a67d2",
                                        },
                                        },
                                    }}
                                    fullWidth
                                    value={this.state.subject}
                                    onChange={this.handleChange}
                                    />
                                    <div className="errorMessage">{this.state.errorSubject} </div>
                                </FormControl>
                                </div>
                                <div className="form-control">
                                <label htmlFor="message">Message</label>
                                <FormControl className="form-control" variant="outlined">
                                    <TextField
                                    id="message"
                                    name="message"
                                    type="text"
                                    className="input"
                                    multiline
                                    rows={5}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                        "& >fieldset": {
                                            borderColor: "#c0c0c0",
                                        },
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                        "& >fieldset": {
                                            borderColor: "#1a67d2",
                                        },
                                        },
                                    }}
                                    fullWidth
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                    />
                                    <div className="errorMessage">{this.state.errorMessage} </div>
                                </FormControl>
                                </div>
                                <div className="form-control">
                                {random_captcha ? (
                                    <div className="captcha_holder">
                                    <div className="captcha_label">
                                        <h6>{random_captcha}</h6>
                                    </div>
                                    <Button
                                        className="captcha_btn"
                                        variant="text"
                                        size="small"
                                        onClick={() => this.getRecaptcha()}
                                    >
                                        Regenerate
                                    </Button>
                                    </div>
                                ) : null}
                                <FormControl className="form-control" variant="outlined">
                                    <TextField
                                    placeholder="Enter the code above"
                                    id="captcha"
                                    name="captcha"
                                    type="text"
                                    className="input"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                        "& >fieldset": {
                                            borderColor: "#c0c0c0",
                                        },
                                        },
                                        "& .MuiOutlinedInput-root:hover": {
                                        "& >fieldset": {
                                            borderColor: "#1a67d2",
                                        },
                                        },
                                    }}
                                    fullWidth
                                    value={this.state.captcha}
                                    onChange={this.handleChange}
                                    />
                                    <div className="errorMessage">{this.state.errorCaptcha} </div>
                                </FormControl>
                                </div>
                                <div className="btnRight">
                                <ButtonGradient className="sendBtn" onClick={this.sendMessage}>
                                    Send Message
                                </ButtonGradient>
                                </div>
                                <Snackbar
                                open={this.state.success}
                                autoHideDuration={6000}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                onClose={() => this.setState({ success: false })}
                                >
                                <Alert variant="filled" severity="success">
                                    {this.state.alertMessage}
                                </Alert>
                                </Snackbar>
                                <Snackbar
                                open={this.state.error}
                                autoHideDuration={6000}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                onClose={() => this.setState({ error: false })}
                                >
                                <Alert variant="filled" severity="error">
                                    {this.state.alertMessage}
                                </Alert>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}