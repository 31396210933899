import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import 'typeface-roboto'
import "typeface-inter";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import SignUpHeader from "./SignUpHeader";

export default class BasePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metaData: {},
      isCorpLogin: false
    };
  }
  async componentDidMount() {
    
    setTimeout(() => {
      this.setState({
        metaData: {
          url: "https://www.lexiscard.asia/",
          name: "LexisCard",
          datePublished: "2022-04-14",
          description:
            "Share your business card with unlimited contacts and network seamlessly on the go with LexisCard. Just flash your digital card QR code or share with your contacts.",
          language: "en-US",
          author: {
            email: "contact@lexiscard.asia",
            name: "LexisCard",
          },
        }
      });
    }, 1000);
  }

  handleLogoClick = () => {
    const animation = this.state.animation;
    if(animation){
      this.setState({
        animation: !animation,
      });
    }else{
      this.setState({
        ...this.state,
        animation: true,
      });
    }
    
  }

  render(Page) {
    let path = window.location.pathname;
    const isCorpLogin = this.state.isRegister;
    
    return (
      <>
        <ReactSEOMetaTags
          render={(el: React.ReactNode) => (
            <HelmetProvider>
              <Helmet>{el}</Helmet>
            </HelmetProvider>
          )}
          website={this.state.metaData}
        />
        {path.includes("/Corp") ? !path.includes("/Corp/Result") && !path.includes("/Corp/Activate") && !path.includes("/Corp/Verification") ? <SignUpHeader tabActive={this.state.tabActive} isLogin={isCorpLogin} /> : null : !path.includes("/profile") ? <Header handleLogoClick={this.handleLogoClick} /> : null}
        {Page}
        {
          !path.includes("/Corp") ? !path.includes("/profile") ? <Footer /> : null : null
        }
        
      </>
    );
  }
}
