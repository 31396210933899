import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import BasePageComponent from '../components/BasePageComponent';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import "./Verification.scss"
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { ApiPost } from '../services/Api';
import { FaLessThanEqual } from 'react-icons/fa';


class Verification extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            btnDisable: false,
            timerVal: 180,
            timerID: null,
            data: {},
            btnCode: false,
        }
    }
    componentDidMount(){
        this.isactivate();
        this.handleInterval();
        
    }

    componentWillUnmount() {
        clearInterval(this.state.timerID);
    }

    handleInterval = () => {
        const timerID = setInterval(() => {
            const timerVal = this.state.timerVal;
            if (timerVal === 0) {
                clearInterval(timerID); // clear interval when countdown reaches 0
                return;
              }
            this.setState(state => ({
                timerVal: state.timerVal - 1,
                btnDisable: true
            }), () => {
                const timerVal = this.state.timerVal;
                if(timerVal <= 0){
                    this.setState({
                        btnDisable: false
                    })
                }
            })
        }, 1000);

        this.setState({ timerID })
    }

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
        "h": hours,
        "m": minutes,
        "s": seconds
        };
        return minutes+":"+String(seconds).padStart(2, "0");
    }

    handleResendVerification = async () => {
        this.setState(state => ({
            timerVal: 180
        }),
        () => {
            this.handleInterval()
        })

        const check = await this.isactivate();
        if(check){
            const auth = Cookies.get("auth");
            if(auth){
                const cred = JSON.parse(auth);
                try {
                    const resp = await ApiPost("/corp/resendEmail",{user_id: cred.user_id, email: cred.email})
                    const {message} = resp.data;
                    console.log("message", message)
                } catch (error) {
                    console.log("resend error", error)
                }
            }
            
        }else{
            this.setState({
                btnCode: false
            });
        }
    }

    isactivate = async () => {
        const auth = Cookies.get("auth");
        if(auth){
            const cred = JSON.parse(auth);
            try {
                const check = await ApiPost("/corp/getUserActivated", {user_id: cred.user_id});
                console.log("check",check)
                if(check.data.message === 'failed'){
                    this.setState({
                        btnCode: true
                    })
                    return true;
                }else{
                    return false;
                }
            } catch (error) {
                console.log("error",error)
                return false;
            }
        }else{
            return false;
        }
        
    }

    handleProceed = () => {
        window.location.href = "./Signup"
    }

    render() {
        const btnDisable = this.state.btnDisable;
        const timer_data = this.state.timerVal;
        const btnCode = this.state.btnCode;

        return super.render(
            <div id='Verification'>
                <Card className='card' sx={{ minWidth: 275, maxWidth: 400 }}>
                    <Box component="div" className="icon-holder">
                        <div className="icon-round" style={{backgroundColor: '#01082E'}}>
                            <EmailIcon className='icon' fontSize="large" />
                        </div>
                    </Box>
                    <CardContent className='content'>
                        <Typography className='title' variant="h5" component="div">
                           Verification.
                        </Typography>
                        <Typography className='sub-details' style={{whiteSpace: 'pre-line'}}>
                            {
                                btnCode ? "An activation link has been sent to your email, click resend if you did not received one." : "Your account has already been verified. Please click the 'proceed' button to access your account."
                            }
                        </Typography>
                    </CardContent>
                    {
                        btnCode ? (
                            <CardActions className='resendHolder'>
                                <Button className='nextBtn' disabled={btnDisable} onClick={() => this.handleResendVerification()} size="small">Resend {timer_data > 0 && btnCode ? this.secondsToTime(timer_data) : null}</Button>
                            </CardActions>
                        ) : (
                            <CardActions className='resendHolder'>
                                <Button className='nextBtn' onClick={() => this.handleProceed()} size="small">Proceed</Button>
                            </CardActions>
                        )
                    }
                </Card>
            </div>
        )
    }
}

const customState = state => {
    return {
        verStatus: state.verStatus
    }
}

const customDispatch = dispatch => {
    return {
        setCorpLogin: (val) => dispatch({type: 'setCorpLogin', value: val}),
        setVerStatus: (val) => dispatch({type: 'setCorpLogin', value: val})
    }
}

export default connect(customState, customDispatch)(Verification)