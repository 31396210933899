// PrivacyPolicy.js
import React from 'react';
import BasePageComponent from '../components/BasePageComponent';
import LanguageDropdown from './Transalation/LanguageDropdown';
import './PrivacyPolicy.scss';
import { withTranslation } from 'react-i18next';

class PrivacyPolicy extends BasePageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderContent() {
    const { t } = this.props;

    return (
      <div className="PrivacyPolicy">
        <div className="container">
          <LanguageDropdown />

          {/* First Section */}
          <h1>{t('company')}</h1>
          <h2>{t('data_notice')}</h2>
          <p className="mt-0">{t('effective')} 01 July 2022.</p>
          <p dangerouslySetInnerHTML={{ __html: t('notice_description') }} />

          {/* Second Section */}
          <h3>{t('personal_data')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('personal_data_content') }} />

          {/* Third Section */}
          <h3>{t('collection_use')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('collection_use_content') }} />

          {/* Fourth Section */}
          <h3>{t('deemed_consent')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('deemed_consent_content') }} />

          {/* Fifth Section */}
          <h3>{t('reliance_interest')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('reliance_interest_content') }} />

          {/* Sixth Section */}
          <h3>{t('withdraw_consent')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('withdraw_consent_content') }} />

          {/* Seventh Section */}
          <h3>{t('access_correction')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('access_correction_content') }} />

          {/* Eight Section */}
          <h3>{t('protection')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('protection_content') }} />

          {/* Ninth Section */}
          <h3>{t('accuracy')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('accuracy_content') }} />

          {/* Tenth Section */}
          <h3>{t('retention')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('retention_content') }} />

          {/* Eleventh Section */}
          <h3>{t('transfer_data')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('transfer_data_content') }} />

          {/* Twelfth Section */}
          <h3>{t('protection_officer')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('protection_officer_content') }} />

          {/* Thirteenth Section */}
          <h3>{t('notice_effect')}</h3>
          <div dangerouslySetInnerHTML={{ __html: t('notice_effect_content') }} />

        </div>
      </div>
    );
  }

  render() {
    return super.render(this.renderContent());
  }
}

export default withTranslation()(PrivacyPolicy);
