import BasePageComponent from '../../components/BasePageComponent';
import "./Profile.scss";
import { connect } from 'react-redux';
import AppLoader from '../../components/AppLoader';
import { Container, Box, Fab, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CryptoJS from 'crypto-js';
import SendIcon from '@mui/icons-material/Send';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ApiPost, ApiGet } from '../../services/Api';
import QRCode from "react-qr-code";
import lexiscard_logo from "./Frame.png";
import not_found_logo from "./404.png"
import Modal from 'react-modal';
import SocialButton from './SocialButton';
import { getEnvVars } from '../../services/Environment';

// ICONS //
import AddCardIcon from '@mui/icons-material/AddCard';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import FaxIcon from '@mui/icons-material/Fax';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReactHtmlParser from 'react-html-parser';
import 'buffer';


class Profile extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: false,
            anchorElTel: false,
            data: null,
            corpMeta: {},
            isDropdownOpen: false,
            isDropdownOpenIcon: false,
            profileData: null,
            doneProfileFetch: false,
            isModalOpen: false,
            imageWidth: 0,
            imageHeight: 0,
            screenWidth: window.innerWidth,
            isImageLoaded: false,
            isQrModalOpen: false,
            showNotification: false,
            isOwner: false,
            showNotification: false,
            isOwner: false,
            themeData: [
                {
                    "id": 'default',
                    "header_background": ['#AB66FF', '#74B6F7'],
                    "profile_border_color": ['#AB66FF'],
                    "name_text_color": '#FFFFFF',
                    "name_text_font_family": 'Inter',
                    "position_text_color": '#9C9C9C',
                    "position_text_font_family": 'Inter',
                    "company_text_color": ['#AB66FF', '#74B6F7'],
                    "company_text_font_family": 'Inter',
                    "icon_color": '#AB66FF',
                    "details_text_color": '#FFFFFF',
                    "details_font_family": 'Inter',
                    "is_uppercase": false,
                    "button_color": ['#AB66FF', '#74B6F7'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": '',
                    "background_color": '#01082E'
                },
                {
                    "id": 'orange_variant',
                    "header_background": ['#1D1D1D'],
                    "profile_border_color": ['#FFAF66'],
                    "name_text_color": '#FFFFFF',
                    "name_text_font_family": 'Oswald',
                    "position_text_color": '#FFFFFF',
                    "position_text_font_family": 'Inter',
                    "company_text_color": ['#FFFFFF'],
                    "company_text_font_family": 'Inter',
                    "icon_color": '#FFFFFF',
                    "details_text_color": '#FFFFFF',
                    "details_font_family": 'Oswald',
                    "is_uppercase": true,
                    "button_color": ['#FFAF66', '#FF8A00'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/orange_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'gray_and_white_variant',
                    "header_background": ['#D1D1D1'],
                    "profile_border_color": ['#E8E8E8'],
                    "name_text_color": '#1A1A1A',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#515151',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#7F7F7F'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#D0D0D0',
                    "details_text_color": '#1A1A1A',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#1C1C1C'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": '',
                    "background_color": '#F1F1F1'
                },
                {
                    "id": 'black_variant',
                    "header_background": ['#00072D', '#4D4A5F', '#928DAB'],
                    "profile_border_color": ['#626262'],
                    "name_text_color": '#FFFFFF',
                    "name_text_font_family": 'Lora',
                    "position_text_color": '#515151',
                    "position_text_font_family": 'Lora',
                    "company_text_color": ['#FFF7F7'],
                    "company_text_font_family": 'Lora',
                    "icon_color": '#666666',
                    "details_text_color": '#FFFFFF',
                    "details_font_family": 'Lora',
                    "is_uppercase": false,
                    "button_color": ['#EAEAEA'],
                    "button_text_color": '#000103',
                    "button_text_font_family": 'Inter',
                    "background": '',
                    "background_color": '#000103'
                },
                {
                    "id": 'red_and_black_variant',
                    "header_background": ['#DE2828'],
                    "profile_border_color": ['#480303'],
                    "name_text_color": '#DE2828',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#F1F1F1',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#979797'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#DE2828',
                    "details_text_color": '#FFFFFF',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#DE2828'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/red_and_black_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'blue_and_black_variant',
                    "header_background": ['#289DDE'],
                    "profile_border_color": ['#0C5B87'],
                    "name_text_color": '#289DDE',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#F1F1F1',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#979797'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#289DDE',
                    "details_text_color": '#FFFFFF',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#289DDE'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/blue_and_black_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'yellow_and_black_variant',
                    "header_background": ['#DEAB28'],
                    "profile_border_color": ['#56410A'],
                    "name_text_color": '#DEAB28',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#F1F1F1',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#979797'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#DEAB28',
                    "details_text_color": '#FFFFFF',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#DEAB28'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/yellow_and_black_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'light_blue_variant',
                    "header_background": ['#9AC8F8'],
                    "profile_border_color": ['#DAE3FF'],
                    "name_text_color": '#007BCE',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#007BCE',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#2D6096'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#2D6096',
                    "details_text_color": '#007BCE',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#9AC8F8'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/light_blue_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'purple_and_pink_variant',
                    "header_background": ['#B234ED'],
                    "profile_border_color": ['#AC009B'],
                    "name_text_color": '#AC009B',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#0D0D0D',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#979797'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#AC009B',
                    "details_text_color": '#F760D6',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#B234ED'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/purple_and_pink_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'red_and_white_variant',
                    "header_background": ['#DE2828'],
                    "profile_border_color": ['#E5E5E5'],
                    "name_text_color": '#DE2828',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#F1F1F1',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#979797'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#DE2828',
                    "details_text_color": '#141414',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#DE2828'],
                    "button_text_color": '#141414',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/red_and_white_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'light_blue_and_white_variant',
                    "header_background": ['#289DDE'],
                    "profile_border_color": ['#0C5B87'],
                    "name_text_color": '#289DDE',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#F1F1F1',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#979797'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#289DDE',
                    "details_text_color": '#141414',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#289DDE'],
                    "button_text_color": '#141414',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/light_blue_and_white_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'yellow_and_white_variant',
                    "header_background": ['#DEAB28'],
                    "profile_border_color": ['#56410A'],
                    "name_text_color": '#DEAB28',
                    "name_text_font_family": 'Poppins',
                    "position_text_color": '#F1F1F1',
                    "position_text_font_family": 'Poppins',
                    "company_text_color": ['#979797'],
                    "company_text_font_family": 'Poppins',
                    "icon_color": '#DEAB28',
                    "details_text_color": '#141414',
                    "details_font_family": 'Poppins',
                    "is_uppercase": false,
                    "button_color": ['#DEAB28'],
                    "button_text_color": '#141414',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/yellow_and_white_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'black_wood_variant',
                    "header_background": ['#1D1D1D'],
                    "profile_border_color": ['#AB66FF', '#8D91FB', '#74B6F7'],
                    "name_text_color": '#FFFFFF',
                    "name_text_font_family": 'Oswald',
                    "position_text_color": '#FFFFFF',
                    "position_text_font_family": 'Inter',
                    "company_text_color": ['#FFFFFF'],
                    "company_text_font_family": 'Inter',
                    "icon_color": '#FFFFFF',
                    "details_text_color": '#FFFFFF',
                    "details_font_family": 'Oswald',
                    "is_uppercase": true,
                    "button_color": ['#AB66FF', '#8D91FB', '#74B6F7'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/black_wood_variant.png'),
                    "background_color": ''
                },
                {
                    "id": 'brown_wood_variant',
                    "header_background": ['#1D1D1D'],
                    "profile_border_color": ['#FFAF66'],
                    "name_text_color": '#482402',
                    "name_text_font_family": 'Oswald',
                    "position_text_color": '#482402',
                    "position_text_font_family": 'Inter',
                    "company_text_color": ['#482402'],
                    "company_text_font_family": 'Inter',
                    "icon_color": '#482402',
                    "details_text_color": '#482402',
                    "details_font_family": 'Oswald',
                    "is_uppercase": true,
                    "button_color": ['#553415'],
                    "button_text_color": '#FFFFFF',
                    "button_text_font_family": 'Inter',
                    "background": require('./images/brown_wood_variant.png'),
                    "background_color": ''
                }
            ]
        }
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            screenWidth: window.innerWidth,
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidMount(){
        const searchParams = new URLSearchParams(window.location.search);
        const data = searchParams.get("data");

        if (data) {
            this.setState({ data });
            this.getCorpProfileDetails(data);
        } else {
            console.warn("Code parameter not found in the URL.");
        }

        // const hasVisited = localStorage.getItem('profileFirstVisit');

        // if (!hasVisited) {

        //     // This is the first visit, show notification
        //     this.setState({ showNotification: true });

        //     // Set a key in local storage to mark that the user has visited the profile
        //     localStorage.setItem('profileFirstVisit', 'true');
        // }
    }

    getCorpDetails = async (data) => {
        try {
            if (data) {
                const useremail = data.email;
                const isCorp = data.isCorp;
    

                const resp = await ApiPost('/corp/getCorpsMeta', { useremail: useremail, isCorp:isCorp });
    
                if (resp.data.message === "success") {
                    this.setState({ corpMeta: resp.data.data });
                }
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    }

    getCorpProfileDetails = async (data) => {
        try {
            const resp = await ApiPost('/corp/getProfileData', { data: data });
            const profile_data = resp.data.data

            // console.log('data?.profile_theme : ',profile_data)
            if (profile_data.custom_profile) {
                this.addNewTheme(profile_data.custom_profile);
            }

            this.setState({ profileData: profile_data});
            this.getCorpDetails(profile_data);

            this.setState({ doneProfileFetch: true});
        } catch (error) {
            console.error('An error occurred while fetching profile data:', error);
        }
    }

    addNewTheme = (newTheme) => {
        this.setState(prevState => ({
            themeData: [...prevState.themeData, newTheme]
        }));
    }

    handleSendMessage = (phoneNumber, sendVia = "SMS") => {
        if(!phoneNumber) return

        const userAgent = window.navigator.userAgent;
        const message = "Hi! I scanned your QR code from the LexisCard app. Thanks for connecting!";
        let cleanedPhoneNumber = phoneNumber.replace(/\s+/g, '').replace(/^\+/, '');

        if (!cleanedPhoneNumber.startsWith('+')) {
            cleanedPhoneNumber = '+' + cleanedPhoneNumber;
        }

        if(sendVia === "Telegram"){    
            const telegramURL = `https://telegram.me/${cleanedPhoneNumber}`;
            window.open(telegramURL, '_blank');
        }else if(sendVia === "WhatsApp"){

            let newNumber;
            if (cleanedPhoneNumber.includes(',')) {
                const splitNumber = cleanedPhoneNumber.split(',');
                newNumber = splitNumber[0].replace(/\s/g, '');
            } else {
                newNumber = cleanedPhoneNumber.replace(/\s/g, '');
            }
            
            const whatsAppURL = `https://wa.me/${newNumber}?text=${message}`;
            window.location.href = whatsAppURL;
        }else if(sendVia === "WeChat"){
            const weChatUrl = `weixin://contacts/profile/wxid_0bjrwutyuozg22`;
            window.location.href = weChatUrl;
        }else{
            if (/android/i.test(userAgent)) {
                const smsUrl = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
                window.location.href = smsUrl;
            } else if (/iphone|ipad|ipod/i.test(userAgent)) {
                const smsUrl = `sms:${phoneNumber}&body=${encodeURIComponent(message)}`;
                window.location.href = smsUrl;
            }
        }
    };

    handleAddToPhoneBook = (contactInfo) => {
        // Generate a VCard string from the contactInfo
        // const vcard = this.generateVCard(contactInfo);
    
        // if (navigator.contacts) {
        //     // Check if the Contacts API is available (Cordova/PhoneGap)
        //     this.addToContacts(vcard);
        // } else {
        //     console.log('The Contacts API is not supported in this browser.');
        // }
        const vcard = this.generateVCard(contactInfo, true);

        this.downloadVCard(vcard);
    }

    downloadVCard = (vcardContent) => {
        const blob = new Blob([vcardContent], { type: 'text/vcard' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contact.vcf';
        a.click();
        window.URL.revokeObjectURL(url);
    }
    
    addToContacts = (vcard) => {
        // Open a contact form with the generated VCard
        const opts = { multiple: false, description: 'Add this contact to your address book', body: vcard };
        navigator.contacts.select(
            ['name', 'phoneNumbers', 'emails', 'organizations'],
            (contacts) => {
                console.log('Contact added successfully:', contacts);
            },
            (error) => {
                console.error('Error adding contact:', error);
            },
            opts
        );
    }

    isJson = (item) => {
        item = typeof item !== "string" ? JSON.stringify(item) : item;
    
        try {
          item = JSON.parse(item);
        } catch (e) {
          return false;
        }
    
        if (typeof item === "object" && item !== null) {
          return true;
        }
    }

    checkNumber = (obj) => {
        if (obj && typeof obj === "object") {
            return  obj.formatedNum;
        }else{
            return  obj;
        }
    }  

    checkObjectField = (obj) => {
        if (obj && typeof obj === "object") {

            let formatNumber = obj?.number
            if(obj?.extension){
                formatNumber = `${obj?.number}, ${obj?.extension}`
            }
        
            return  formatNumber;
        }else{
            return  obj;
        }
    }

    generateVCard = (contactInfo, isDownload = false) => {
        
        const name = contactInfo?.name ? contactInfo?.name : "";
        const local_name = contactInfo?.local_name ? contactInfo?.local_name : "";
        const company = contactInfo?.company ? contactInfo?.company : contactInfo?.corps_name ? contactInfo?.corps_name : "";
        const position = contactInfo?.position ? contactInfo?.position : "";
        const department = contactInfo?.department ? contactInfo?.department : "";
        const division = contactInfo?.division ? contactInfo?.division : "";
        const address = contactInfo?.address ? contactInfo?.address : "";
        const office_number = contactInfo?.office_number ? this.isJson(contactInfo.office_number) ? contactInfo.office_number.extension ? `${contactInfo.office_number.number}, ${contactInfo.office_number.extension}` : this.checkNumber(contactInfo.office_number?.number) : this.checkNumber(contactInfo?.office_number) : "";
        const mobile_number = contactInfo?.mobile_number ? this.isJson(contactInfo.mobile_number) ? contactInfo.mobile_number.extension ? `${contactInfo.mobile_number.number}, ${contactInfo.mobile_number.extension}` : this.checkNumber(contactInfo.mobile_number?.number) : this.checkNumber(contactInfo?.mobile_number) : "";
        const fax_number = contactInfo?.fax_number ? this.isJson(contactInfo.fax_number) ? contactInfo.fax_number.extension ? `${contactInfo.fax_number.number}, ${contactInfo.fax_number.extension}` : this.checkNumber(contactInfo.fax_number?.number) : this.checkNumber(contactInfo?.fax_number) : "";
        const company_number = contactInfo?.company_number ? this.checkNumber(contactInfo?.company_number) : "";
        const email = contactInfo?.email ? contactInfo?.email : "";
        const website = contactInfo?.website ? contactInfo?.website : "";
        const building = contactInfo?.building ? contactInfo?.building : "";
        const room = contactInfo?.room ? contactInfo?.room : "";
        const street = contactInfo?.street ? contactInfo?.street : "";
        const country = contactInfo?.country ? contactInfo?.country : "";
        const otherImage = contactInfo?.other_image_url ? contactInfo?.other_image_url : ""
        const corp_id = contactInfo?.corp_id ? contactInfo?.corp_id : ""

        let websiteLength = 1;
        let websiteData;
        if (website.includes("|")) {
            const splitWebsite = website.split("|");
            websiteData = splitWebsite;
            websiteLength = splitWebsite.length;
        } else if (website.includes(",")) {
            const splitWebsite = website.split(",");
            websiteData = splitWebsite;
            websiteLength = splitWebsite.length;
        } else {
            websiteData = [website];
        }

        const vcard = [
            "BEGIN:VCARD",
            `VERSION:3.0`,
            `N:${name}`,
            `FN:${name}`,
            `LN:${local_name}`,
            `ORG:${company}`,
            `TITLE:${position}`,
            `DEPARTMENT:${department}`,
            `DIVISION:${division}`,
            `ADR:${address}`,
            `TEL;TYPE=work:${office_number}`,
            `TEL;TYPE=cell:${mobile_number}`,
            `TEL;TYPE=fax:${fax_number}`,
            `TEL;TYPE=company:${company_number}`,
            `EMAIL;WORK;INTERNET:${email}`,
        ];

        if(contactInfo?.uuid){
            vcard.push(`URL:${this.CreateNamecardLink(contactInfo?.uuid)}`);
        }

        if(isDownload){
            if(contactInfo?.profile_photo_base64){
                vcard.push(`PHOTO;TYPE=PNG;ENCODING=b:${contactInfo?.profile_photo_base64}`);
            }

            if(contactInfo?.logo_base64){
                vcard.push(`PHOTO;TYPE=PNG;ENCODING=b:${contactInfo?.logo_base64}`);
            }  
        }

        for (let index = 0; index < websiteLength; index++) {
            vcard.push(`URL:${websiteData[index]}`);
        }

        vcard.push(
            `BUILDING:${building}`,
            `STREET:${street}`,
            `ROOM:${room}`,
            `COUNTRY:${country}`,
            `OTHERIMAGE:${otherImage}`,
            `CORPID:${corp_id}`,
            "END:VCARD"
        );

        return vcard.join("\r\n");
    }

    CreateNamecardLink = (uuid) => {
        const { apiUrl } = getEnvVars();
        const getUniqueCode = uuid?.substring(0, 8);
        return `${apiUrl}/nameCard/${getUniqueCode}`;
    };

    handleSend = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    handleCloseSend = () => {
        this.setState({ anchorEl: null });
    };

    handleSendSocial = (event) => {
        this.setState({ anchorElTel: event.currentTarget });
    };
    
    handleCloseSendSocial = () => {
        this.setState({ anchorElTel: null });
    };

    addMobileNumbers(numberObject, label, mobile_numbers) {
        if(numberObject){
            const value = this.checkObjectField(numberObject)

            if (value) {
                mobile_numbers = [...mobile_numbers, value]
                return mobile_numbers;
            }
        }
        return mobile_numbers;
    }

    handleConnectButtonClick = () => {
        this.setState((prevState) => ({ isDropdownOpen: !prevState.isDropdownOpen }));
    };

    handleConnectButtonClickIcon = () => {
        this.setState((prevState) => ({ isDropdownOpenIcon: !prevState.isDropdownOpenIcon }));
    };

    handleSendEmailClick = (email) => {
        const mailtoLink = `mailto:${email}`;
        window.location.href = mailtoLink;
    };

    handleOpenGoogleMaps = (address) => {
        const mapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
        window.open(mapsLink, '_blank');
    };

    handleOpenWebsite = (website) => {
        if (!website.startsWith('http://') && !website.startsWith('https://')) {
            website = 'http://' + website;
        }
        window.open(website, '_blank');
    };

    handleOpenWebsitePopover = (event, website) => {
        this.setState({ websitePopoverAnchorEl: event.currentTarget, currentWebsite: website });
    };

    handleCloseWebsitePopover = () => {
        this.setState({ websitePopoverAnchorEl: null, currentWebsite: null });
    };

    toggleModal = () => {
        this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
    };

    toggleQrModal = () => {
        this.setState((prevState) => ({ isQrModalOpen: !prevState.isQrModalOpen }));
    };

    handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        this.setState({
            imageWidth: naturalWidth,
            imageHeight: naturalHeight,
            isImageLoaded: true
        });
    };

    render() {
        const { data, corpMeta, mobileNumbers, isDropdownOpen, isDropdownOpenIcon, profileData, doneProfileFetch, isModalOpen, imageWidth, imageHeight, screenWidth, isImageLoaded, isQrModalOpen, showNotification, themeData } = this.state;
        const id = this.state.anchorEl ? 'simple-popover' : undefined;

        let itemData = null;
        let imageUrl = null;
        let name = null;
        let position = null;
        let company = null;
        let logo = null;
        let office_number = null;
        let mobile_number = null;
        let mobile_other = null;
        let mobile_china = null;
        let mobile_numbers = [];
        let office_numbers = [];
        let website = [];
        let aboutUs = null;
        let profile_photo = null;

        const rotationAngle = imageHeight > imageWidth ? '0deg' : '90deg';

        if (data) {
            try {
                if (profileData) {
                    itemData = profileData;

                    imageUrl = itemData?.img_url && itemData?.img_url[0]?.img1 !== '' ? itemData?.img_url[0]?.img1 : itemData?.other_image_url ? itemData?.other_image_url[0]?.img1 !== '' ? itemData.other_image_url[0].img1 : itemData.captured_img_url[0].img1 : ''
                    name = itemData?.name ? itemData.name : ''
                    position = itemData?.position ? itemData.position : ''
                    company = itemData?.company ? itemData.company : ''
                    logo = itemData?.logo ? itemData.logo : ''
                    mobile_number = itemData?.mobile_number ? this.isJson(itemData?.mobile_number) ? itemData?.mobile_number.extension ? `${itemData?.mobile_number.number}, ${itemData?.mobile_number.extension}` : this.checkNumber(itemData?.mobile_number?.number) : this.checkNumber(itemData?.mobile_number) :  null;
                    mobile_other = itemData?.mobile_number_o ? itemData.mobile_number_o : ''
                    mobile_china = itemData?.mobile_number_o_ch ? itemData.mobile_number_o_ch : ''
                    aboutUs = itemData?.isCorp ? corpMeta?.aboutUs ? corpMeta.aboutUs : '' : itemData?.about ? itemData.about : ''
                    office_number = itemData?.office_number ? this.isJson(itemData?.office_number) ? itemData?.office_number.extension ? `${itemData?.office_number.number}, ${itemData?.office_number.extension}` : this.checkNumber(itemData?.office_number?.number) : this.checkNumber(itemData?.office_number) :  null;
                    mobile_numbers = this.addMobileNumbers(itemData?.mobile_number, 'Mobile Number', mobile_numbers);
                    profile_photo = itemData?.profile_photo_url ? itemData?.profile_photo_url : ''

                    if(itemData?.mobile_number_o){
                        mobile_numbers = this.addMobileNumbers(itemData?.mobile_number_o, 'Mobile Number', mobile_numbers);
                    }

                    if(itemData?.mobile_number_o_ch){
                        mobile_numbers = this.addMobileNumbers(itemData?.mobile_number_o_ch, 'Mobile Number', mobile_numbers);
                    }
                    
                    if(itemData?.other_fields?.length > 0){
                        itemData.other_fields.forEach((field) => {
                            if (field.type === 'text' && field.text === 'Mobile Number' && field.value) {
                                mobile_numbers = this.addMobileNumbers(field.value, 'Mobile Number', mobile_numbers);
                            }
                        });
                    }

                    office_numbers = this.addMobileNumbers(itemData?.office_number, 'Office Number', office_numbers);
                    office_numbers = this.addMobileNumbers(itemData?.company_number, 'Office Number', office_numbers);

                    if(itemData?.phone_number){
                        office_numbers = this.addMobileNumbers(itemData?.phone_number, 'Office Number', office_numbers);
                    }

                    if(itemData?.other_fields?.length > 0){
                        itemData.other_fields.forEach((field) => {
                            if (field.type === 'text' && field.text === 'Office Number' && field.value) {
                                office_numbers = this.addMobileNumbers(field.value, 'Phone Number', office_numbers);
                            }
                        });
                    }

                    if(itemData.website){
                        if (itemData.website.includes("|")) {
                          const splitWebsite = itemData.website.split("|");
                          website = splitWebsite;
                        } else if (itemData.website.includes(",")) {
                          const splitWebsite = itemData.website.split(",");
                          website = splitWebsite;
                        }
                    }
                    
                } else {
                    console.error("Decryption produced no data.");
                }
            } catch (error) {
                console.error("Error decrypting data:", error);
            }
        } else {
            console.warn("Data is null or not found.");
        }

        // const fieldsToDisplay = ["address", "email", "office_number", "company_number", "mobile_number", "mobile_number_o", "mobile_number_o_ch", "fax_number", "website", ];
        const fieldsAddEmailDisplay = ["address", "email"];
        const fieldsTelephoneDisplay = ["office_number", "company_number"];
        const fieldsMobileDisplay = ["mobile_number", "mobile_number_o", "mobile_number_o_ch"];
        const fieldsFaxWebsiteDisplay = ["fax_number", "website"];
        const sendToNumbers = ["mobile_number", "mobile_number_o", "mobile_number_o_ch"];

        const Item = styled(Paper)(({ theme }) => ({
            backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            ...theme.typography.body2,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        }));

        let selectedTheme;
        if (itemData?.profile_theme) {
            selectedTheme = themeData.filter(theme => theme.id === itemData?.profile_theme);
        } else {
            selectedTheme = themeData.filter(theme => theme.id === 'default');  
        }

        let address = '';

        if (this.checkObjectField(itemData?.address)) {
            address = `${this.checkObjectField(itemData?.address)}`;
        }

        if (this.checkObjectField(itemData?.address_line_1)) {
            address += `${address ? ', ' : ''}${this.checkObjectField(itemData?.address_line_1)}`;
        }

        if (this.checkObjectField(itemData?.address_line_2)) {
            address += `${address ? ', ' : ''}${this.checkObjectField(itemData?.address_line_2)}`;
        }

        if (this.checkObjectField(itemData?.address_line_3)) {
            address += `${address ? ', ' : ''}${this.checkObjectField(itemData?.address_line_3)}`;
        }

        return super.render(
            <div id='Profile-card'>
                {/* {
                    showNotification && (
                        <div className="notification">
                            🎉 Welcome! This is your first visit to your profile page!
                        </div>
                    )
                } */}
                {
                    profileData ? profileData?.subscription_exp ? <div className='profile-container-card'>
                        <div className='profile-card'>
                            <div className='profile-card-header'>
                                <div className='profile-card-header-banner-main-container' style={{ background: selectedTheme[0].header_background.length > 1 ? `linear-gradient(to right, ${selectedTheme[0].header_background.join(', ')})` : selectedTheme[0].header_background[0] }}>
                                    <div onLoad={this.handleImageLoad} onClick={this.toggleModal} className={imageUrl ? 'profile-card-header-banner-container' : 'profile-card-header-banner-container-color'} style={{backgroundImage: imageUrl ? `url(${imageUrl})` : null}}></div>
                                </div>
                            </div>
                            <div className='profile-card-body' style={{ background: selectedTheme[0].background ? `url(${selectedTheme[0].background})` : selectedTheme[0].background_color, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'  }}>
                                <div className='profile-card-header-image-qr-container'>
                                    <img
                                        className='image-style'
                                        src={profile_photo ? profile_photo : logo ? logo : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'}
                                        style={{ border: selectedTheme[0].profile_border_color.length > 1 ? `8px solid linear-gradient(to right, ${selectedTheme[0].profile_border_color.join(', ')})` : `8px solid ${selectedTheme[0].profile_border_color[0]}` }}
                                    />
                                    <div className='qr-container'>
                                        <QRCode
                                            size={90}
                                            value={this.generateVCard(itemData)}
                                            className='qr-style'
                                            onClick={this.toggleQrModal}
                                        />
                                        <div className='icon-container'>
                                            <div onClick={() => this.handleAddToPhoneBook(itemData)}>
                                                <AddCardIcon style={{ cursor: 'pointer', color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                            </div>
                                            <div onClick={() => this.handleConnectButtonClickIcon()}>
                                                <ConnectWithoutContactIcon style={{ cursor: 'pointer', color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                            </div>
                                            {isDropdownOpenIcon && (
                                                <div className='dropdown-content' style={{position: 'absolute', width: '100%', marginTop: '5%', marginLeft: '10%'}}>
                                                    <button className='dropdown-button' onClick={() => this.handleSendEmailClick(itemData?.email)} size="large" sx={{textAlign: 'start'}}><EmailIcon className='button-icon'/>Send Email</button>
                                                    {
                                                        mobile_numbers?.length > 1 ?
                                                        <>
                                                            <button className='dropdown-button' onClick={this.handleSend} size="large" sx={{textAlign: 'start'}}><MessageIcon className='button-icon'/>Send SMS</button>
                                                            <Popover
                                                                id={id}
                                                                open={Boolean(this.state.anchorEl)}
                                                                anchorEl={this.state.anchorEl}
                                                                onClose={this.handleCloseSend}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                    {mobile_numbers.map((field) => (
                                                                        <>
                                                                            <Button
                                                                                variant="text"
                                                                                onClick={() => {
                                                                                    this.handleSendMessage(field)
                                                                                }}
                                                                                style={{fontSize: 16, paddingLeft: 30, paddingRight: 30, width: '100%'}}
                                                                            >
                                                                                {this.checkObjectField(field)}
                                                                            </Button>
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </Popover>
                                                        </> : mobile_numbers ?
                                                        <div className='btn-option'>
                                                            <button className='dropdown-button' onClick={() => this.handleSendMessage(this.checkObjectField(mobile_number))}><MessageIcon className='button-icon'/>Send SMS</button>
                                                        </div> : null
                                                    }
                                                        <SocialButton
                                                            officeNumbers={mobile_numbers.length > 0 ? mobile_numbers : office_numbers}
                                                            handleSendMessage={this.handleSendMessage}
                                                            handlePopOverOpen={this.handleSendSocial}
                                                            handlePopOverClose={this.handleCloseSendSocial}
                                                            anchorElTel={this.state.anchorElTel}
                                                            screenWidth={screenWidth}
                                                            checkObjectField={this.checkObjectField}
                                                            sendVia="Telegram"
                                                            buttonLabel={<><TelegramIcon className='button-icon' />Send Via Telegram</>}
                                                        />
                                                        <SocialButton
                                                            officeNumbers={mobile_numbers.length > 0 ? mobile_numbers : office_numbers}
                                                            handleSendMessage={this.handleSendMessage}
                                                            handlePopOverOpen={this.handleSendSocial}
                                                            handlePopOverClose={this.handleCloseSendSocial}
                                                            anchorElTel={this.state.anchorElTel}
                                                            screenWidth={screenWidth}
                                                            checkObjectField={this.checkObjectField}
                                                            sendVia="WhatsApp"
                                                            buttonLabel={<><WhatsAppIcon className='button-icon'/>Send Via WhatsApp</>}
                                                        />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Typography className='profile-name' style={{ color: selectedTheme[0].name_text_color, fontFamily: selectedTheme[0].name_text_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{name}</Typography>
                                <Typography className='profile-company' style={{ 
                                    background: selectedTheme[0].company_text_color.length > 1 ? `linear-gradient(to right, ${selectedTheme[0].company_text_color.join(', ')})` : selectedTheme[0].company_text_color[0],
                                    backgroundClip: 'text',
                                    fontFamily: selectedTheme[0].company_text_font_family, 
                                    textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none'
                                }}>{company}</Typography>
                                <Typography className='profile-position' style={{ color: selectedTheme[0].position_text_color, fontFamily: selectedTheme[0].position_text_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{position}</Typography>
                                {itemData && fieldsAddEmailDisplay.map((field) => (
                                    this.checkObjectField(itemData[field]) && (
                                    <div key={field} className="profile-field-container">
                                        {
                                            field === "address" && 
                                            <div className='profile-field-details'>
                                                <LocationOnIcon style={{ color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                                <div onClick={() => this.handleOpenGoogleMaps(address)}>
                                                    <Typography className='profile-field-details-text' style={{ color: selectedTheme[0].details_text_color, fontFamily: selectedTheme[0].details_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{address}</Typography>
                                                </div>
                                            </div>
                                        }
                                        {
                                            field === "email" && 
                                            <div className='profile-field-details' onClick={() => this.handleSendEmailClick(this.checkObjectField(itemData[field]))} >
                                                <EmailIcon style={{ color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                                <Typography className='profile-field-details-text' style={{ color: selectedTheme[0].details_text_color, fontFamily: selectedTheme[0].details_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{this.checkObjectField(itemData[field])}</Typography>
                                            </div>
                                        }
                                    </div>
                                )))}
                                {(this.checkObjectField(itemData?.office_number) || this.checkObjectField(itemData?.company_number)) && 
                                    <div key={'telephone'} className="profile-field-container">
                                        <div className='profile-field-details'>
                                            <PhoneIcon style={{ color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                            <div>
                                                {itemData && fieldsTelephoneDisplay.map((field, index) => (
                                                    this.checkObjectField(itemData[field]) && <div onClick={() => this.handleSendMessage(this.checkObjectField(itemData[field]))}><Typography className='profile-field-details-text' style={{ marginBottom: index !== fieldsTelephoneDisplay.length ? 10 : 0, color: selectedTheme[0].details_text_color, fontFamily: selectedTheme[0].details_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{this.checkObjectField(itemData[field])}</Typography></div>))}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(this.checkObjectField(itemData?.mobile_number) || this.checkObjectField(itemData?.mobile_number_o) || this.checkObjectField(itemData?.mobile_number_o_ch)) && 
                                    <div key={'mobile'} className="profile-field-container">
                                        <div className='profile-field-details'>
                                            <SmartphoneIcon style={{ color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                            <div>
                                                {itemData && fieldsMobileDisplay.map((field, index) => (
                                                    this.checkObjectField(itemData[field]) && <div onClick={() => this.handleSendMessage(this.checkObjectField(itemData[field]))}><Typography className='profile-field-details-text' style={{ marginBottom: index !== fieldsMobileDisplay.length ? 10 : 0, color: selectedTheme[0].details_text_color, fontFamily: selectedTheme[0].details_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{this.checkObjectField(itemData[field])}</Typography></div>))}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {itemData && fieldsFaxWebsiteDisplay.map((field) => (
                                    this.checkObjectField(itemData[field]) && (
                                    <div key={field} className="profile-field-container">
                                        {
                                            field === "fax_number" &&
                                            <div className='profile-field-details'>
                                                <FaxIcon style={{ color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                                <Typography className='profile-field-details-text' style={{ color: selectedTheme[0].details_text_color, fontFamily: selectedTheme[0].details_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{this.checkObjectField(itemData[field])}</Typography>
                                            </div>
                                        }
                                        {
                                            field === "website" ?
                                            website.length > 1 ? <div className='profile-field-details'>
                                                <LanguageIcon style={{ color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                                <div onClick={(event) => this.handleOpenWebsitePopover(event, this.checkObjectField(itemData[field]))}>
                                                    <Typography className='profile-field-details-text' style={{ color: selectedTheme[0].details_text_color, fontFamily: selectedTheme[0].details_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{this.checkObjectField(itemData[field])}</Typography>
                                                </div>
                                                <Popover
                                                    open={Boolean(this.state.websitePopoverAnchorEl)}
                                                    anchorEl={this.state.websitePopoverAnchorEl}
                                                    onClose={this.handleCloseWebsitePopover}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        {website.map((field, index) => (
                                                            <button
                                                                key={index}
                                                                variant="text"
                                                                onClick={() => {
                                                                    this.handleOpenWebsite(this.checkObjectField(field.trim()))
                                                                }}
                                                                style={{ 
                                                                    color: '#FFF', 
                                                                    fontFamily: 'Inter', 
                                                                    fontSize: 16, 
                                                                    fontWeight: 400, 
                                                                    textTransform: 'none', 
                                                                    textAlign: 'left',
                                                                    border: 0,
                                                                    padding: 10,
                                                                    display: 'flex',
                                                                    justifyContent: 'left',
                                                                    alignItems: 'center',
                                                                    background: '#AB66FF'
                                                                }}
                                                            >
                                                                {field.trim()}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </Popover>
                                            </div> : <div className='profile-field-details'>
                                                <LanguageIcon style={{ color: selectedTheme[0].icon_color, fontSize: '24px', marginRight: 10 }} />
                                                <div onClick={() => this.handleOpenWebsite(this.checkObjectField(itemData[field]))}>
                                                    <Typography className='profile-field-details-text' style={{ color: selectedTheme[0].details_text_color, fontFamily: selectedTheme[0].details_font_family, textTransform: selectedTheme[0].is_uppercase ? 'uppercase' : 'none' }}>{this.checkObjectField(itemData[field].trim())}</Typography>
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                )))}
                                <Button className='share-profile-button' onClick={() => this.handleAddToPhoneBook(itemData)} size="large" style={{ background: selectedTheme[0].button_color.length > 1 ? `linear-gradient(to right, ${selectedTheme[0].button_color.join(', ')})` : selectedTheme[0].button_color[0], color: selectedTheme[0].button_text_color, fontFamily: selectedTheme[0].button_text_font_family }}>Add VCARD</Button>
                                <div className='connect-with-me-container'>
                                    <Button className='connect-profile-button' onClick={() => this.handleConnectButtonClick()} size="large" style={{borderBottomLeftRadius: isDropdownOpen ? 0 : 4, borderBottomRightRadius: isDropdownOpen ? 0 : 4, background: selectedTheme[0].button_color.length > 1 ? `linear-gradient(to right, ${selectedTheme[0].button_color.join(', ')})` : selectedTheme[0].button_color[0], color: selectedTheme[0].button_text_color, fontFamily: selectedTheme[0].button_text_font_family }}>Connect With Me</Button>
                                    {isDropdownOpen && (
                                        <div className='dropdown-content'>
                                            <button className='dropdown-button' onClick={() => this.handleSendEmailClick(itemData?.email)} size="large" sx={{textAlign: 'start'}}><EmailIcon className='button-icon'/>Send Email</button>
                                            {
                                                mobile_numbers?.length > 1 ?
                                                <>
                                                    <button className='dropdown-button' onClick={this.handleSend} size="large" sx={{textAlign: 'start'}}><MessageIcon className='button-icon'/>Send SMS</button>
                                                    <Popover
                                                        id={id}
                                                        open={Boolean(this.state.anchorEl)}
                                                        anchorEl={this.state.anchorEl}
                                                        onClose={this.handleCloseSend}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                                            {mobile_numbers.map((field) => (
                                                                <>
                                                                    <Button
                                                                        variant="text"
                                                                        onClick={() => {
                                                                            this.handleSendMessage(field)
                                                                        }}
                                                                        style={{fontSize: 16, paddingLeft: 30, paddingRight: 30, width: '100%'}}
                                                                    >
                                                                        {this.checkObjectField(field)}
                                                                    </Button>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </Popover>
                                                </> : mobile_numbers ?
                                                <div className='btn-option'>
                                                    <button className='dropdown-button' onClick={() => this.handleSendMessage(this.checkObjectField(mobile_number))}><MessageIcon className='button-icon'/>Send SMS</button>
                                                </div> : null
                                            }
                                                <SocialButton
                                                    officeNumbers={mobile_numbers.length > 0 ? mobile_numbers : office_numbers}
                                                    handleSendMessage={this.handleSendMessage}
                                                    handlePopOverOpen={this.handleSendSocial}
                                                    handlePopOverClose={this.handleCloseSendSocial}
                                                    anchorElTel={this.state.anchorElTel}
                                                    screenWidth={screenWidth}
                                                    checkObjectField={this.checkObjectField}
                                                    sendVia="Telegram"
                                                    buttonLabel={<><TelegramIcon className='button-icon' />Send Via Telegram</>}
                                                />
                                                <SocialButton
                                                    officeNumbers={mobile_numbers.length > 0 ? mobile_numbers : office_numbers}
                                                    handleSendMessage={this.handleSendMessage}
                                                    handlePopOverOpen={this.handleSendSocial}
                                                    handlePopOverClose={this.handleCloseSendSocial}
                                                    anchorElTel={this.state.anchorElTel}
                                                    screenWidth={screenWidth}
                                                    checkObjectField={this.checkObjectField}
                                                    sendVia="WhatsApp"
                                                    buttonLabel={<><WhatsAppIcon className='button-icon'/>Send Via WhatsApp</>}
                                                />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='profile-card-footer'>
                                { company && <Typography className='about-us'>About Us</Typography> }
                                { company && <Typography className='profile-card-footer-company'>{company}</Typography>}
                                { corpMeta?.uen && <Typography className='profile-card-footer-uen'>{corpMeta?.uen ? `Unique Entity Number: ${corpMeta.uen}` : ''}</Typography>}
                                { aboutUs && <Typography className='profile-card-footer-about'>
                                {ReactHtmlParser(aboutUs)}
                                </Typography>}
                                <div style={{marginBottom: '8px'}}><img src={lexiscard_logo} style={{width: '100%', height: '100%'}}/></div>
                                <Typography className='profile-card-footer-version'>Version: 1.0.1</Typography>
                            </div>
                        </div>
                    </div> : <div className='profile-container-no-content'>
                        <div className='profile-container-no-content-image'>
                            <img
                                className='image-style-no-content'
                                src={not_found_logo}
                            />
                        </div>
                        <div className='profile-container-no-content-text'>
                            <div className='profile-container-no-content-text-container'>
                                <Typography className='not-found-text'>404</Typography>
                                <Typography className='not-found-text'>Content Not Found</Typography>
                                <Typography className='not-found-content'>Oops, looks like the page you are trying to reach is no longer available. The page could be moved or could be temporarily unavailable.</Typography>
                            </div>
                        </div>
                    </div> : doneProfileFetch ? <div className='profile-container-no-content'>
                        <div className='profile-container-no-content-image'>
                            <img
                                className='image-style-no-content'
                                src={not_found_logo}
                            />
                        </div>
                        <div className='profile-container-no-content-text'>
                            <div className='profile-container-no-content-text-container'>
                                <Typography className='not-found-text'>404</Typography>
                                <Typography className='not-found-text'>Content Not Found</Typography>
                                <Typography className='not-found-content'>Oops, looks like the page you are trying to reach is no longer available. The page could be moved or could be temporarily unavailable.</Typography>
                            </div>
                        </div>
                    </div> : null
                }

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={this.toggleModal}
                    contentLabel="Image Modal"
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: '#00072D'
                        },
                        content: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            padding: '0',
                            border: '0px solid #ccc',
                            backgroundColor: '#00072D',
                            overflow: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                    }}
                >
                    <button
                        onClick={this.toggleModal}
                        style={{
                            color: '#FFF',
                            position: 'absolute',
                            top: '-50%',
                            right: '-50%',
                            backgroundColor: '#00072D',
                            border: 0
                        }}
                    >
                        <CloseIcon />
                    </button>
                    <img
                        src={imageUrl}
                        onLoad={this.handleImageLoad}
                        alt="Banner Image"
                        style={{
                            transform: `rotate(${screenWidth > 980 ? '0deg' : rotationAngle})`,
                            maxWidth: screenWidth * 1.3,
                            maxHeight: screenWidth * 1.4,
                            visibility: isImageLoaded ? 'visible' : 'hidden',
                        }}
                    />
                </Modal>

                <Modal
                    isOpen={isQrModalOpen}
                    onRequestClose={this.toggleQrModal}
                    contentLabel="QR Modal"
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        },
                        content: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            padding: '0',
                            border: '0px solid #ccc',
                            backgroundColor: 'transparent',
                            overflow: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                    }}
                >
                    <button
                        onClick={this.toggleQrModal}
                        style={{
                            position: 'absolute',
                            top: '-50%',
                            right: '-50%',
                            transform: 'translate(50%, 50%)',
                            backgroundColor: '#00072D',
                            border: 0,
                            width: '40px',
                            height: '40px',
                            color: '#FFF'
                        }}
                    >
                        <CloseIcon style={{marginTop: 3}}/>
                    </button>
                    <QRCode
                        width={screenWidth}
                        height={screenWidth}
                        value={this.generateVCard(itemData)}
                        style={{
                            border: '5px solid #FFF',
                            borderRadius: 4
                        }}
                    />
                </Modal>
            </div>
        )
    }
}

export default Profile;