import React from 'react';
import BasePageComponent from '../../components/BasePageComponent';
import "./SelectPlan.scss";
import { Box, Card, CardContent, Button, Divider, Typography } from '@mui/material';
import CardOption from '../../components/CardOption';
import Increament from '../../components/Increament';
import { connect } from 'react-redux';

class SelectPlan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render() {
        const CPro_stat = this.props.data.plan === "CPRO" ? true : false;
        const CPremium_stat = this.props.data.plan === "CPREMIUM" ? true : false;
        const num_user = this.props.data.selectedPlan.number_users;

        return (
            <div id='SelectPlan'>
                <div className='plan-head'>
                    <h3 className='plan-title'>Select Your Plan</h3>
                </div>
                <div className='plan-body'>
                    <div className='card-plan'>
                        <div className='label-holder'>
                            <label className='body-label'>Plan Type</label>
                        </div>
                        <div className='cards-select'>
                            <CardOption data={{
                                title: this.props.data?.all_plan[0]?.plan,
                                amount: this.props.data?.all_plan[0]?.perUser,
                                perYear: this.props.data?.all_plan[0]?.perBaseUser,
                                users: this.props.data?.all_plan[0]?.base_users,
                                baseUser: this.props.data?.all_plan[0]?.base_users
                            }}
                            status={CPro_stat} 
                            onClick={(val) => this.props.handleSelectedPlan(val)} />
                            <CardOption data={{
                                title: this.props.data?.all_plan[1]?.plan,
                                amount: this.props.data?.all_plan[1]?.perUser,
                                perYear: this.props.data?.all_plan[1]?.perBaseUser,
                                users: this.props.data?.all_plan[1]?.base_users,
                                baseUser: this.props.data?.all_plan[1]?.base_users
                            }} 
                            status={CPremium_stat} 
                            onClick={(val) => this.props.handleSelectedPlan(val)} />
                        </div>
                    </div>
                    <div className='num-user'>
                        <div className='label-holder'>
                            <label className='body-label number-label'>Number of Users</label>
                        </div>
                        <Increament number={num_user} handleMinus={() => this.props.handleMinusUser()} handleAdd={() => this.props.handleAddUser()} />
                    </div>
                </div>
                <div className='plan-footer'>
                    <Button onClick={() => this.props.setActivetab(1)} className='nextBtn' size="large">Next</Button>
                </div>    
            </div>
        )
    }
}


const customDispatch = dispatch => {
    return{
        setActivetab: (val) => dispatch({type: 'setActiveTab', value: val})
    }
}

export default connect(null, customDispatch)(SelectPlan)