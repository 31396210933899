import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-dropdown" style={{marginBottom: 20}}>
      <select onChange={(e) => changeLanguage(e.target.value)} value={i18n.language} style={{padding: 8, fontSize: 16}}>
        <option value="en">English</option>
        <option value="zh">中文</option>
        {/* Add more language options as needed */}
      </select>
    </div>
  );
};

export default LanguageDropdown;