import React, { useRef, useEffect, useState } from 'react';
import Hammer from 'hammerjs';
import "./ResponsiveCarousel.scss";
import { ApiPost } from '../services/Api';
import moment from 'moment/moment';

const cardsData = [
  {
    event_date: {date:'Thur, August 15, 2024', time:'12:00 PM - 10:00 PM GMT +08:00'},
    title: 'Singapore Food Festival',
    venue : {description: 'Clarke Quay, Singapore'},
    images: ['https://images.lifestyleasia.com/wp-content/uploads/sites/6/2022/08/12201040/67251059_1657139211083133_7151496151319969792_n-1349x900.jpeg']
  },
  {
    event_date: {date:'Sat, April 20, 2024', time:'10:00 AM - 6:00 PM GMT +08:00'},
    title: 'Art in the Park: Sculpture Exhibition',
    venue : {description: 'Singapore Botanic Gardens'},
    images: ['https://www.nationalgallery.sg/sites/default/files/nothing-forever-main-text.jpg']
  },
  {
    event_date: {date:'Sat, June 8, 2024', time:'7:00 PM - 11:00 PM GMT +08:00'},
    title: 'Singapore Jazz Night',
    venue : {description: 'Esplanade - Theatres on the Bay, Singapore'},
    images: ['https://media.cntraveler.com/photos/6071fd81d1058698d13c31a8/16:9/w_2560,c_limit/Esplanade%20with%20Singapore%20Skyline.%20jpg.jpg']
  },
  {
    event_date: {date:'November 15-25, 2024', time:'7:00 PM - 11:00 PM GMT +08:00'},
    title: 'Singapore International Film Festival',
    venue : {description: 'Various cinemas across Singapore'},
    images: ['https://asianfilmfestivalscomsite.files.wordpress.com/2020/05/sgff2020call.png?w=640']
  },
  {
    event_date: {date:'September 5-7, 2024', time:'9:00 AM - 6:00 PM GMT +08:00'},
    title: 'Singapore Tech Expo',
    venue : {description: 'Sands Expo & Convention Centre, Marina Bay Sands'},
    images: ['https://www.pcma.org/wp-content/uploads/2024/01/Sands-Expo-Convention-Centre.png']
  }
];

const ResponsiveCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDonePopup, setShowDonePopup] = useState(false);
  const tinderContainerRef = useRef(null);
  const cardsRef1 = useRef(null);
  const cardsRef2 = useRef(null);
  const cardsRef3 = useRef(null);
  const cardsRef4 = useRef(null);
  const [eventData, setEventData] = useState(cardsData);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    if (!isFetching) {
      updateCardStyles();
      initializeSwipe();
    }
  }, [currentIndex, isFetching]);

  const getCardStyles = (index) => ({
    zIndex: 4 - index,
    transform: `scale(${1 - index * 0.05}) translateY(-${index * 30}px)`,
    opacity: `${1 - index * 0.2}`,
  });

  const updateCardStyles = () => {
    const tinderContainer = tinderContainerRef.current;
    const allCards = [cardsRef1.current, cardsRef2.current, cardsRef3.current, cardsRef4.current];

    const cardsLeftToShow = Math.min(4, eventData.length - currentIndex);

    const activeCards = allCards.slice(0, cardsLeftToShow);

    activeCards.forEach((card, index) => {
      const styles = getCardStyles(index);
      card.style.zIndex = styles.zIndex;
      card.style.transform = styles.transform;
      card.style.opacity = styles.opacity;
    });

    initializeSwipe();
  };

  const updateCards = () => {
    if (currentIndex < eventData.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const handleButtonClick = (love) => () => {
    const cards = Array.from(tinderContainerRef.current.querySelectorAll('.tinder--card:not(.removed)'));
    const moveOutWidth = document.body.clientWidth * 1.5;
    if (!cards.length) return;
    const card = cards[0];
    card.classList.add('removed');
    card.style.transform = love ? `translate(${moveOutWidth}px, -100px) rotate(-30deg)` : `translate(-${moveOutWidth}px, -100px) rotate(30deg)`;
    setTimeout(() => updateCards(), 150);
  };

  const initializeSwipe = () => {
    const topCard = cardsRef1.current;

    if (!topCard) return;

    if (topCard.hammer) {
      topCard.hammer.destroy();
    }

    const hammertime = new Hammer(topCard);
    topCard.hammer = hammertime;

    hammertime.on('panmove', (event) => {
      const rotation = event.deltaX / 20;
      topCard.style.transform = `translate(${event.deltaX}px, ${event.deltaY}px) rotate(${rotation}deg)`;
    });

    hammertime.on('panend', (event) => {
      if (event.deltaX === 0) return;

      const moveOutWidth = document.body.clientWidth;
      const keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

      if (!keep) {
        const endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
        const toX = event.deltaX > 0 ? endX : -endX;
        topCard.style.transform = `translate(${toX}px, 0) rotate(${(event.deltaX > 0 ? 1 : -1) * 15}deg)`;
        setTimeout(() => updateCards(), 50);
      } else {
        topCard.style.transform = '';
      }
    });
  };

  const getEvents = async () => {
    await ApiPost("/getEvents")
    .then(async (response) => {
      if (response.data.data.length > 1) {
        const formattedData = response.data.data.map((data) => {
          const dateStart = moment(data.date_start).format('MMMM DD, YYYY');
          const dateEnd = moment(data.date_end).format('MMMM DD, YYYY');
          const timeStart = moment(data.date_start).format('h:mm A');

          return {
            ...data,
            event_date: {
              date: `${dateStart} - ${dateEnd}`,
              time: `${timeStart} GMT +08:00`
            }
          };
        });
        setEventData(formattedData)
      }
      setIsFetching(false)
    })
    .catch((error) => {
      console.log("Get events error: ", error)
    })
  }

  return (
    <div>
      <div>
        <div className="tinder" ref={tinderContainerRef}>
          {
            !isFetching && eventData.slice(currentIndex, currentIndex + 4).map((card, index) => (
              <div key={index} className="tinder--card" ref={index === 0 ? cardsRef1 : index === 1 ? cardsRef2 : index === 2 ? cardsRef3 : cardsRef4} style={getCardStyles(index)}>
                <div className="card-image">
                  <img src={card.images[0]} alt={card.images[0]}/>
                </div>
                <div className="card-main">
                  <p className="card-date">{card.event_date.date},<br/>{card.event_date.time}</p>
                  <p className='card-name'>{card.title}</p>
                  <p className='card-location'>{card.venue.description}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCarousel;
