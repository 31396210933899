import React, { useRef, useEffect, useState } from 'react';
import Hammer from 'hammerjs';
import "./LifeStyle.scss";


const data = [
    {
        label:"We've Got Your Event Covered",
        category:'Courses',
        image:'assets/images/ls1.png'
    },
    {
        label:"Your All-Inclusive Event Solution",
        category:'Services',
        image:'assets/images/ls2.png'
    },
    {
        label:"We Take Care of Everything",
        category:'Retail',
        image:'assets/images/ls3.png'
    },
    {
        label:"Leave No Detail Behind",
        category:'Dining',
        image:'assets/images/ls4.png'
    }
]


const Card = ({label,category,image}) =>{
    return <div className='card_container'>
            <p className='label'>{label}</p>
            <p className='category'>{category}</p>
            <div className='image_container'>
               <img  className='image' src={image}/>
            </div>
        
    </div>
}


const LifeStyle = () =>{
    return(
            <div className='life_style_contianer'>
                <div className='secondary_container'>
                        <div className='image_container'>
                            <img src='assets/images/lexis_vendor_dark.png'/>
                        </div>

                        <p className='tag_line'>List your complementary lifestyle <br/>
                        services & products as a LexisVendor</p>

                        <div className='card_holders'>
                            {data.map((item)=>{
                                return <Card label={item.label} category={item.category} image={item.image}/>
                            })}
                        </div>
                </div>
             
            </div>
    )
}

export default LifeStyle