import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import BasePageComponent from '../components/BasePageComponent';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ApiGet, ApiPost } from '../services/Api';
import "./Activate.scss"
import { connect } from 'react-redux';
import {Base64} from 'js-base64';


class Activate extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            code: null,
            status : 'Pending',
            text: 'Hang tight while we activate your account!'
        }
    }

    componentDidMount(){
        let url = window.location.search.substring(1);
        url = url.split("=");
        let code = url[url.length -1];
        this.setState(() => ({
            code: code
        }),
        () => {
            this.activateAccount()
        })
    }

    activateAccount = async () => {
        const code = this.state.code;
        try {
            const resp = await ApiPost("/corp/activateAccount", {code: code});
            const message = resp.data.message;
            if(message === "success"){
                this.setState({
                    status: 'Activated',
                    text: 'Your account has been successfully activated!'
                })
            }else if(message === "expired") {
                this.setState({
                    status: 'Expired',
                    text: 'The activation code already expired!'
                })
            }else{
                this.setState({
                    status: 'Failed',
                    text: 'There is an issue in activating your account.'
                })
            }

        } catch (error) {
            console.log("error", error);
        }
        
    }

    iconDisplay = () => {
        const {status} = this.state;
        switch (status) {
            case "Activated":
                return <CheckIcon className='icon' fontSize="large" />;
            case "Failed":
                return <CloseIcon className='icon' fontSize="large"/>
            default:
                return <MoreHorizIcon className='icon' fontSize="large"/>
        }
    }

    handleProceed = () => {
        window.location.href = "/Corp/SignUp";
    }

    render() {
        const {status, text} = this.state;

        return super.render(
            <div id='Activate'>
                <Card className='card' sx={{ minWidth: 275, maxWidth: 400 }}>
                    <Box component="div" className="icon-holder">
                        <div className="icon-round" style={status ? {backgroundColor: '#01082E'} : {backgroundColor: '#E5383B'}}>
                            {
                                this.iconDisplay()
                            }
                        </div>
                    </Box>
                    <CardContent className='content'>
                        <Typography className='title' variant="h5" component="div">
                            {status}
                        </Typography>
                        {
                            status === "Activated" || status === "Pending" || status === "Expired" ? (
                                <Typography className='sub-details' style={{whiteSpace: 'pre-line'}}>
                                    {text}
                                </Typography>
                            ) : null
                        }
                        {
                            status === "Failed" ? (
                                <>
                                    <p className='header-title'>
                                        {text}
                                    </p>
                                    <p className='header-text'>
                                        If you believe that it
                                        is an error, please contact us at{" "}
                                        <a href="mailto:contact@lexisjobs.asia">
                                            contact@lexisCard.asia
                                        </a>
                                    .
                                    </p>
                                </>
                            ) : null
                        }
                        
                    </CardContent>
                    {
                        status === "Activated" ? (
                            <CardActions className='btnHolder'>
                                <Button fullWidth size="large" className="successBtn" onClick={() => this.handleProceed()}>Proceed</Button>
                            </CardActions>
                        ) : null
                    }
                </Card>
            </div>
        )
    }
}

const customDispatch = dispatch => {
    return {
        setVerify: (val) => dispatch({type: "setVerify", value: val})
    }
} 

export default connect(null, customDispatch)(Activate)