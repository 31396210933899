import React, { Component} from 'react';
import './CardOption.scss';
import {Box, Typography} from '@mui/material';

export default class CardOption extends Component  { 
    constructor(props){
        super(props)
        this.state = {
        }
    }
    async componentDidMount () {

    }

    render(){
        const {data, onClick, status} = this.props;
        const {title, amount, perYear, users} = data;
        const textColor = status ? '#ffffff' : '#000000';
        return (
            <Box onClick={() => onClick(data)} component="a" href='javascript:void(0)' className='cardPlan' sx={status ? {backgroundColor: '#01082E'} : {backgroundColor: '#ffffff'}} >
                <Box component="div" className='cardSelected' >
                    <Typography className="cardText title" sx={{color: textColor}}>{title}</Typography>
                    <Typography className="cardText" sx={{color: textColor}}>${amount}/year, per user</Typography>
                    <Typography className="cardText" sx={{color: textColor}}>${perYear}/year, {users} users</Typography>
                </Box>
            </Box>
        )
    }
}