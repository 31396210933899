import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


export default{
    userdata: {}
}

export const GlobalContext = React.createContext([])


export const ButtonGradient = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#2964F5"),
    background: "linear-gradient(90deg, #AB66FF 0%, #74B6F7 100%);",
    '&:hover': {
      background: "linear-gradient(90deg, #AB66FF 0%, #74B6F7 70%);",
    },
    textTransform:"capitalize",
    fontFamily:"inter",
    fontSize: "16px",
    fontWeight:"400",
    padding:"6px 16px"
}));

export const GhostButtonGradient = styled(Button)(({ theme }) => {
  const darkColor = theme.palette.primary.dark;
  
  return {
      color: '#0B1B35',
      background: "transparent",
      border: `2px solid ${darkColor}`,
      fontWeight: "700", // Making the text bolder initially
      '&:hover': {
        color: theme.palette.getContrastText("#2964F5"),
        background: `linear-gradient(90deg, #AB66FF 0%, #74B6F7 100%)`,
        border: `0px solid ${darkColor}`,
        fontWeight: "700", // Making the text even bolder on hover
      },
      textTransform: "capitalize",
      fontFamily: "inter",
      fontSize: "16px",
      padding: "6px 16px"
  };
});

const ENV = {
  redirect: window.location.href.includes("localhost") ? 'https://dev-corp.lexiscard.asia' : window.location.href.includes("dev-web") ? 'https://dev-corp.lexiscard.asia' : 'https://corp.lexiscard.asia'
};

export const Corp_web = ENV.redirect

export const windowsHeight = () => {
  let height = window.innerHeight;
  window.addEventListener("resize", function(){
      height = window.innerHeight;
  })
  height = height - Number(height * 0.21)
  return height;
}