import { Box } from '@mui/material';
import React, { Component} from 'react';
import { Oval } from  'react-loader-spinner';

export default class AppLoader extends Component  { 
    constructor(props){
        super(props)
        this.state = {
        }
    }
    async componentDidMount () {

    }

    render(){
        return (
            <Box
            component="div"
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(254, 254, 254, 0.5)'
            }} >
                <Oval
                    height={80}
                    width={80}
                    color="#666666"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#666666"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </Box>
            
        )
    }
}