import React, { Component } from "react";
import "./MenuDrawer.scss";
import ListItemButton from '@mui/material/ListItemButton';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ButtonGradient } from "../Globals";

export default class MenuDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false,
      path: "",
    };
  }
  async componentDidMount() {
    let url = window.location.pathname;
    setTimeout(() => {
      this.setState({ path: url });
      console.log(this.state.path);
    }, 200);
  }
  openDrawerMethod = () => {
    this.setState({ openDrawer: true });
  };
  closeDrawerMethod = () => {
    this.setState({ openDrawer: false });
  };
  scrollToHeroMobile = () => {
    window.location.href = "/";
  };
  scrollToPartnerUsMobile = () => {
    document
      .getElementById("partnerUS")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToWhyUsMobile = () => {
    document
      .getElementById("lexisSuite")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToHowWorksMobile = () => {
    document
      .getElementById("howWorks")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToPriceMobile = () => {
    document
      .getElementById("pricing")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToContactMobile = () => {
    document
      .getElementById("contact")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  openWhyUs = () => {
    window.location.href = "/#lexisSuite";
  };
  openHowWorks = () => {
    window.location.href = "/#howWorks";
  };
  openPrice = () => {
    window.location.href = "/#pricing";
  };
  openContact = () => {
    window.location.href = "/ContactUs";
  };
  list = (isCorp) => (
    <Box
      role="presentation"
      onClick={this.closeDrawerMethod}
      onKeyDown={this.closeDrawerMethod}
      className="MenuDrawer"
    >
      <List>
        <ListItem
          className="logo-container center"
          // onClick={this.scrollToHeroMobile}
        >
          <IconButton onClick={this.props.menuClick} className={isCorp ? "close-button corp" : "close-button"}>
            <CloseIcon fontSize="large" />
          </IconButton>
          <img
            className="logo"
            src={isCorp ? "../assets/images/LexisCard_Logo3.png" : "assets/images/LexisCard_Logo.png"}
            alt="logo"
          />
        </ListItem>
      </List>
      {(this.state.path === null || this.state.path === "/") && (
        <>
          <List className="navLink">
            <ListItemButton component="a" href="javascript:void(0)" onClick={this.scrollToPartnerUsMobile}>
              <ListItemText primary="Partner Us" />
            </ListItemButton>
            <ListItemButton component="a" href="javascript:void(0)" onClick={this.scrollToWhyUsMobile}>
              <ListItemText primary="Why LexisSuite" />
            </ListItemButton>
            <ListItemButton component="a" href="javascript:void(0)" onClick={this.scrollToHowWorksMobile}>
              <ListItemText primary="How It Works" />
            </ListItemButton>
            <ListItemButton component="a" href="javascript:void(0)" onClick={this.scrollToPriceMobile}>
              <ListItemText primary="Pricing" />
            </ListItemButton>

            <Divider className="divider" />
            <ListItem>
              <ListItemText>
                <ButtonGradient
                  variant="contained"
                  color="secondary"
                  fullWidth
                  // onClick={this.scrollToContactMobile}
                  onClick={this.openContact}
                >
                  Contact Us
                </ButtonGradient>
              </ListItemText>
            </ListItem>
          </List>
        </>
      )}
      {(this.state.path === "/PrivacyPolicy" ||
      this.state.path === "/ContactUs" ||
        this.state.path === "/TermsConditions") && (
        <>
          <List className="navLink">
            <ListItemButton component="a" href="javascript:void(0)" onClick={this.openWhyUs}>
              <ListItemText primary="Why LexisSuite" />
            </ListItemButton>
            <ListItemButton component="a" href="javascript:void(0)" onClick={this.openHowWorks}>
              <ListItemText primary="How It Works" />
            </ListItemButton>
            <ListItemButton component="a" href="javascript:void(0)" onClick={this.openPrice}>
              <ListItemText primary="Pricing" />
            </ListItemButton>

            <Divider className="divider" />
            <ListItem>
              <ListItemText>
                <ButtonGradient
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={this.openContact}
                >
                  Contact Us
                </ButtonGradient>
              </ListItemText>
            </ListItem>
          </List>
        </>
      )}

      {isCorp && (
        <>
          <List className="navLink">
            {
              !this.props.isActiveTab ? (
                <ListItemButton component="a" href="javascript:void(0)" onClick={this.props.handleLogin}>
                  <ListItemText sx={{color: '#000000'}} primary="Login" />
                </ListItemButton>
              ) : (
                <ListItemButton component="a" href="javascript:void(0)" onClick={this.props.handleLogout}>
                  <ListItemText sx={{color: '#000000'}} primary="Logout" />
                </ListItemButton>
              )
            }
            
          </List>
        </>
      )}

      {/* <Divider />
          <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List> */}
    </Box>
  );

  render() {
    const isCorp = this.state.path.includes("/Corp")
    return (
      <Drawer
        anchor={"left"}
        open={this.state.openDrawer}
        onClose={this.closeDrawerMethod}
        PaperProps={{
          sx: isCorp ? { backgroundColor: "#ffffff" } : { backgroundColor: "#00072d" }
        }}
      >
        {this.list(isCorp)}
      </Drawer>
    );
  }
}

// export default function MenuDrawer(props) {

//   const [state, setState] = React.useState({
//     top: false,
//     left: props.openDrawer ? props.openDrawer : false,
//     bottom: false,
//     right: false,
//   });

//   if (props.openDrawer){
//     setState({ ...state, left: true });
//   }
//   const toggleDrawer = (anchor, open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };

//   const list = (anchor) => (
//     <Box
//       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >
//       <List>
//         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {['All mail', 'Trash', 'Spam'].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   return (
//     <div>
//          <Drawer
//             anchor={'left'}
//             open={state['left']}
//             onClose={toggleDrawer('left', false)}
//           >
//             {list('left')}
//           </Drawer>
//     </div>
//   );
// }
