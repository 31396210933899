import React, { Component } from "react";
import "./Header.scss";
import { ButtonGradient, GhostButtonGradient } from "../Globals";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuDrawer from "./MenuDrawer";

export default class PageComponent extends Component {
  listener = null;
  constructor(props) {
    super(props);
    this.state = {
      _menuDrawer: React.createRef(),
      color: "transparent",
      path: "",
      isSlide: false,
      isScrolling: false,
      isAnimate: true
    };
  }

  listenScrollEvent = (e) => {
    if (window.innerWidth > 990) {
      if (window.scrollY > 100) {
        this.setState({ color: "#ffffff", isScrolling: true });
      } else {
        this.setState({ color: "transparent", isScrolling: false });
      }
    } else {
      if (window.scrollY > 10) {
        this.setState({ color: "#ffffff", isScrolling: true });
      } else {
        this.setState({ color: "transparent", isScrolling: false });
      }
    }
  };

  handleResize = () => {
    if (window.innerWidth <= 980) {
      this.setState({ isAnimate: false });
    } else {
      this.setState({ isAnimate: true });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
    window.addEventListener("resize", this.handleResize);
    let url = window.location.pathname;
    setTimeout(() => {
      this.setState({ path: url });
      console.log(this.state.path);
    }, 200);
  }
  menuClick = () => {
    this.state._menuDrawer.current.openDrawerMethod();
  };
  scollToHome = () => {
    document
      .getElementById("hero")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToPartnerUs = () => {
    document
      .getElementById("partnerUS")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToWhyUs = () => {
    document
      .getElementById("lexisSuite")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToHowWorks = () => {
    document
      .getElementById("howWorks")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  scrollToPrice = () => {
    // document
    //   .getElementById("pricingMain")
    //   .scrollIntoView({ behavior: "smooth", block: "center" });
  };
  openAnimate = () => {
    // this.setState({isSlide: !this.state.isSlide})
    // this.props.handleLogoClick()
  }
  openHome = () => {
    window.location.href = "/";
  };
  openWhyUs = () => {
    window.location.href = "/#lexisSuite";
  };
  openHowWorks = () => {
    window.location.href = "/#howWorks";
  };
  openPrice = () => {
    window.location.href = "https://chugo.asia/lexissuite";
  };
  openContact = () => {
    // window.location.href = "/#contactUs";
    window.location.href = "https://corp.lexiscard.asia/";
  };
  openLexisShop = () => {
    window.location.href = "https://shop.lexiscard.asia/";
  };
  render() {
    const is_includes = this.state.path.includes("/voucher");
    const isSlide = this.props.isSlide
    const isScrolling = this.state.isScrolling

    return (
      <>
        <div className="Header">
          <nav className="container" style={{ background: this.state.color }}>
            <div className="containerBody">
              
                  <>
                    {
                      !is_includes ? (
                        <>
                          <div className="menu-icon">
                            <IconButton onClick={this.menuClick}>
                              <MenuIcon fontSize="large" menuClick={this.menuClick} style={!isScrolling && this.state.color === "transparent" ? {color: '#FFFFFF'} : {}} />
                            </IconButton>
                          </div>
                          <MenuDrawer ref={this.state._menuDrawer} />
                        </>
                      ) : null
                    }
                    {(this.state.path === null || this.state.path === "/") && (
                      <>
                        <div className="header-holder" style={this.state.isAnimate ? {maxWidth: '60%', justifyContent: 'center',backgroundColor:"transparent",width:"60%"} : {}}>
                          <img
                            className="logo"
                            src={this.state.isAnimate && this.state.color === "transparent" ? window.innerWidth <= 980 ? "assets/images/lexiscard_logo_dark.png" : "assets/images/LexisEvent_Logo_White.png" : "assets/images/lexiscard_logo_dark.png"}
                            alt="lexiscard logo"
                            onClick={this.scollToHome}
                          />

                          <div className="menu" >
                            <div className="menu-text"  style={{color:this.state.isAnimate && this.state.color === "transparent" && 'white',marginLeft:"25px"}}  onClick={this.scrollToPartnerUs}>
                              Partner Us
                            </div>
                            <div className="menu-text"  style={{color:this.state.isAnimate && this.state.color === "transparent" &&'white',marginLeft:"25px"}} onClick={this.scrollToWhyUs}>
                              Why LexisSuite
                            </div>
                            <div className="menu-text"  style={{color:this.state.isAnimate && this.state.color === "transparent" &&'white',marginLeft:"25px"}} onClick={this.scrollToHowWorks}>
                              How It Works
                            </div>
                            <div className="menu-text"  style={{color:this.state.isAnimate && this.state.color === "transparent" &&'white',marginLeft:"25px"}} onClick={this.openPrice}>
                              Pricing
                            </div>
                          </div>
                        </div>
                        <div className="navHolder">
                          <img
                            className="logo2"
                            src={"assets/images/lexisEvent.png"}
                            alt="lexiscard logo"
                            onClick={this.openAnimate}
                          />
                          <ButtonGradient
                            variant="contained"
                            color="secondary"
                            style={{width:"180px"}}
                            onClick={this.openContact}
                          >
                            Corporate Log in
                          </ButtonGradient>
                          <GhostButtonGradient
                            variant="contained"
                            color="secondary"
                            style={{width:"180px"}}
                            onClick={this.openLexisShop}
                          >
                            Shop Now
                          </GhostButtonGradient>
                          
                        </div>
                      </>
                    )}

                    {(this.state.path === "/PrivacyPolicy" ||
                    this.state.path === "/ContactUs" ||
                      this.state.path === "/TermsConditions") && (
                      <>
                       <div className="header-holder" style={this.state.isSlide ? {maxWidth: '60%', justifyContent: 'flex-start', gap: 20} : {}}>
                        <img
                          className="logo"
                          src={isScrolling && this.state.color !== "transparent" ? "assets/images/lexiscard_logo_dark.png" : "assets/images/LexisCard_Logo.png"}
                          alt="lexiscard logo"
                          onClick={this.openHome}
                        />
                        <div className="menu" style={{marginTop: 10}}>
                          <div className="menu-text" style={!isScrolling && this.state.color === "transparent" ? {color: '#FFFFFF'} : {}} onClick={this.openWhyUs}>
                            Why LexisSuite
                          </div>
                          <div className="menu-text" style={!isScrolling && this.state.color === "transparent" ? {color: '#FFFFFF'} : {}} onClick={this.openHowWorks}>
                            How It Works
                          </div>
                          <div className="menu-text" style={!isScrolling && this.state.color === "transparent" ? {color: '#FFFFFF'} : {}} onClick={this.openPrice}>
                            Pricing
                          </div>
                        </div>
                       </div>
                        <div className="navHolderV2">
                          <ButtonGradient
                            variant="contained"
                            color="secondary"
                            onClick={this.openContact}
                            className="logInButton"
                          >
                            Corporates Log in
                          </ButtonGradient>
                        </div>
                      </>
                    )}
                  </>
            </div>
          </nav>
        </div>
      </>
    );
  }
}
