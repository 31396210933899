import React, { Component } from 'react';
import './InnovativeFeature.scss'

export default class InnovativeFeatures extends Component  { 
    constructor(props){
        super(props)
        this.state = {}
    }

    async componentDidMount () {}

    render(){
        const {data} = this.props;
        const {image, title, paragraph, icon_image, isLeft, upper_img} = data;
        return (
            <div className='mainContainer'>
                {
                    isLeft ? <>
                        <div className='detailsContainer'>
                            {
                                upper_img && <img className='upper_img' src={upper_img}/>
                            }
                            <p className='title'>{title}</p>
                            <div className="contentDivider"></div>
                            <p className='paragraph'>{paragraph}</p>
                            <img className='icon_image' src={icon_image}/>
                        </div>
                        <img className='imageContainer' src={image}/>
                    </> : <>
                        <img className='imageContainer' src={image}/>
                        <div className='detailsContainerLeft'>
                            {
                                upper_img && <img className='upper_img' src={upper_img}/>
                            }
                            <p className='title'>{title}</p>
                            <div className="contentDivider"></div>
                            <p className='paragraph'>{paragraph}</p>
                            <img className='icon_image' src={icon_image}/>
                        </div>
                    </>
                }
            </div>
        )
    }
}